import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Layout } from 'antd';
import logo from 'assets/logo.svg';
// import facebook from 'assets/facebook.svg'; removed as wanted by biz
// import linkedin from 'assets/linkedin.svg'; removed as wanted by biz
import { services, company } from 'libs/publicPages';
import gaEvent from 'libs/gaEvent';
// import { OutboundLink } from 'react-ga'; removed as wanted by biz
import Attributions from 'components/Attributions';
import './Footer.less';

const { Footer } = Layout;

const colLayout = {
  xs: 24,
  sm: 5,
  md: 5,
  lg: 5,
  xl: 5,
};

const servicesFooterItems = Object.keys(services).map((service, index) => (
  <Link
    onClick={() =>
      gaEvent('Footer', 'Navigation', `${services[service]} Clicked`)
    }
    className="footerLink"
    key={index}
    to={service}
  >
    {services[service]}
  </Link>
));

const companyFooterItems = Object.keys(company).map((c, index) => (
  <Link
    onClick={() => gaEvent('Footer', 'Navigation', `${company[c]} Clicked`)}
    key={index}
    className="footerLink"
    to={c}
  >
    {company[c]}
  </Link>
));

const AppFooter = () => (
  <Footer className="app-footer">
    <Row style={{ margin: '0 auto', width: '100%' }} gutter={16}>
      <Col xs={24} sm={9} md={9} lg={9} xl={9} className="app-footer-col">
        <img src={logo} className="app-logo space-bottom" alt="INLT" />
        <p className="license">
          INLT is a licensed customs broker with the ability to file entries
          anywhere in the United States. All entries are subject to INLT audit,
          review, and acceptance.
        </p>
        <br />
        <h4 className="copyright">&#9400; Copyright 2020</h4>
        <Attributions />
      </Col>
    </Row>
  </Footer>
);

export default AppFooter;
