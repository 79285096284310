import React from 'react';
import PropTypes from 'prop-types';
import RawHTML from 'components/RawHTML';
import './FAQ.less';
import { Col, Row } from 'antd';

const FAQ = ({ faqs }) => {
  const faqsJSX = faqs.map((faq, id) => (
    <div key={id}>
      <h3>{faq.title}</h3>
      <RawHTML html={faq.text} />
    </div>
  ));
  const middleIndex = Math.ceil(faqs.length / 2);
  const column1 = faqsJSX.slice(0, middleIndex);
  const column2 = faqsJSX.slice(middleIndex);
  const faqLayout = {
    xs: 24,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12,
  };
  return (
    <div id="faq" style={{}}>
      <Row gutter={64}>
        <Col {...faqLayout}>{column1}</Col>
        <Col {...faqLayout}>{column2}</Col>
      </Row>
    </div>
  );
};

FAQ.propTypes = {
  faqs: PropTypes.array.isRequired,
};

export default FAQ;
