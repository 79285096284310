import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import createSentryMiddleware from 'redux-sentry-middleware';
import * as Sentry from '@sentry/browser';
import rootReducer from './reducers';

export default (preloadedState) => {
  const logMiddleWare = createLogger({
    predicate: (getState, action) => {
      if (
        action.type.startsWith('FETCH_') ||
        action.type.startsWith('@@router/') ||
        action.type.endsWith('_LOADING')
      ) {
        return false;
      } else {
        return true;
      }
    },
    collapsed: true,
  });

  const sentryMiddleware = createSentryMiddleware(Sentry, {
    stateTransformer: (state) => {
      // clone state so as not to mess up the redux store when cleaning the object
      const clonedState = JSON.parse(JSON.stringify(state));

      // remove anything that could be problematically large.
      delete clonedState.code;

      return clonedState;
    },
    actionTransformer: (action) => {
      // only checking for size right now, pretty crude. Might be able to improve by taking out specific keys like urls, docs, etc.
      const stringifiedAction = JSON.stringify(action);
      return stringifiedAction.length < 320000
        ? JSON.parse(stringifiedAction)
        : { error: 'Redux action too large to transmit' };
    },
  });

  const middleware = [thunkMiddleware];

  if (process.env.REACT_APP_STAGE !== 'dev') middleware.push(sentryMiddleware);

  if (
    process.env.REACT_APP_STAGE === 'dev' ||
    process.env.REACT_APP_STAGE === 'test'
  )
    middleware.push(logMiddleWare);

  const enhancer = compose(applyMiddleware(...middleware));

  return createStore(rootReducer, preloadedState, enhancer);
};
