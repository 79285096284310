import GA from 'react-ga';
import Butter from 'buttercms';
import getFullFormKeys from 'libs/getFullFormKeys';
import { get } from 'lodash';
import sentryCatch from 'libs/sentryCatch';
import * as Sentry from '@sentry/browser';

const {
  REACT_APP_STAGE: stage,
  REACT_APP_VERSION: version,
  REACT_APP_BUTTER_API_KEY: butterAPIKey,
  REACT_APP_GA_ID: gaId,
  REACT_APP_SENTRY_DSN: sentryDSN,
  REACT_APP_LINKEDIN_ID: linkedInId,
  REACT_APP_DRIFT_ID: driftId,
} = process.env;

export const initButter = () =>
  (window.butter = Butter('32fec7b4f5a01f0960eb7065fe6b9b8fd4e90ce3', false, 6000));

export const initGA = () => {
  if (stage === 'prod') {
    GA.initialize(gaId);
    // GA.set({appVersion: version});
  } else {
    GA.initialize('foo', { testMode: true });
    // GA.set({ appVersion: version });
    window.GA = GA;
  }
};

export const initSentry = () => {
  if (stage !== 'dev') {
    Sentry.init({
      dsn: sentryDSN,
      environment: stage,
      release: version,
      attachStacktrace: true,
      // captureUnhandledRejections: false, // old option, new option below
      integrations: [
        new Sentry.Integrations.GlobalHandlers({
          onunhandledrejection: false,
        }),
      ],
      beforeSend: (event, hint) => {
        const errMsg = get(hint, 'originalException.message', '');
        if (
          errMsg === 'User is not logged in' ||
          errMsg.startsWith('The entry was already confirmed') ||
          errMsg.includes('Already signed') ||
          errMsg.startsWith('An address change was already made')
        ) {
          return null;
        }
        return event;
      },
      blacklistUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
    });
  }
  window.addEventListener('unhandledrejection', (event) => {
    const errorKeys = getFullFormKeys({
      values: event.reason,
      whitelist: ['errors'],
    });
    const formError = errorKeys.some((x) => x.endsWith('.errors'));
    if (formError) {
      event.preventDefault();
    } else {
      if (stage !== 'dev') {
        // ignore cogito login expiration errors, they just have to log back int
        if (get(event, 'reason.code') !== 'NotAuthorizedException') {
          sentryCatch(event.reason);
        }
      }
    }
  });
};

export const initLinkedIn = () => {
  if (stage !== 'prod') return null;
  window._linkedin_data_partner_id = linkedInId.toString();
  (function() {
    const s = document.getElementsByTagName('script')[0];
    const b = document.createElement('script');
    b.type = 'text/javascript';
    b.async = true;
    b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
    s.parentNode.insertBefore(b, s);
  })();
};

export const initIntercom = () => {
  if (stage !== 'prod') return null;
  (function() {
    const w = window;
    const ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      const d = document;
      // eslint-disable-next-line
      var i = function() {
        // eslint-disable-next-line
        i.c(arguments);
      };
      i.q = [];
      i.c = function(args) {
        i.q.push(args);
      };
      w.Intercom = i;
      // eslint-disable-next-line
      function l() {
        const s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src =
          'https://widget.intercom.io/widget/' +
          process.env.REACT_APP_INTERCOM_ID;
        s.integrity = 'sha256-KxxmHbWYTuBFfwaAzmmnxI4gTI3Pm/h83rSrRgzfGiU=';
        s.crossOrigin = 'anonymous';
        const x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      }
      if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
};

export const startIntercom = (attributes = {}) => {
  if (stage !== 'prod') return null;
  window.Intercom('boot', {
    app_id: process.env.REACT_APP_INTERCOM_ID,
    horizontal_padding: 37,
    vertical_padding: 20,
    ...attributes,
  });
};

export const initDrift = (userId, driftAttributes) => {
  if (stage !== 'prod') return null;
  // eslint-disable-next-line
  !(function() {
    let t;
    // eslint-disable-next-line
    if (t = window.driftt = window.drift = window.driftt || [], !t.init) return t.invoked ? void (window.console && console.error && console.error("Drift snippet included twice.")) : (t.invoked = !0,
          (t.methods = [
            'identify',
            'config',
            'track',
            'reset',
            'debug',
            'show',
            'ping',
            'page',
            'hide',
            'off',
            'on',
          ]),
          (t.factory = function(e) {
            return function() {
              let n;
              // eslint-disable-next-line
        return n = Array.prototype.slice.call(arguments), n.unshift(e), t.push(n), t;
            };
          }),
          t.methods.forEach(function(e) {
            t[e] = t.factory(e);
          }),
          (t.load = function(t) {
            let e;
            let n;
            let o;
            let i;
            // eslint-disable-next-line
      e = 3e5, i = Math.ceil(new Date() / e) * e, o = document.createElement("script"),
              (o.type = 'text/javascript'),
              (o.async = !0),
              (o.crossorigin = 'anonymous'),
              (o.src = 'https://js.driftt.com/include/' + i + '/' + t + '.js'),
              (n = document.getElementsByTagName('script')[0]),
              n.parentNode.insertBefore(o, n);
          }));
  })();
  window.drift.SNIPPET_VERSION = '0.3.1';
  window.drift.load(driftId);

  if (window.drift) {
    window.drift.on('ready', (api, payload) => {
      window.drift.config({ enableWelcomeMessage: false });
      if (userId && driftAttributes)
        window.drift.identify(userId, driftAttributes);
    });
    window.drift.on('startConversation', () => {
      GA.event({ category: 'Drift', action: 'Chart Started' });
    });
    window.drift.on('emailCapture', (data) => {
      GA.event({ category: 'Drift', action: 'Email Captured' });
    });
    window.drift.on('scheduling:meetingBooked', (data) => {
      GA.event({ category: 'Drift', action: 'Meeting Booked' });
    });
    window.drift.on('siderbarOpen', () => {
      GA.event({ category: 'Drift', action: 'Sidebar Opened' });
    });
    window.drift.on('siderbarClosed', () => {
      GA.event({ category: 'Drift', action: 'Sidebar Closed' });
    });
  }
};
