import { Component } from 'react';
import { withRouter } from 'react-router';

// eslint-disable-next-line require-jsdoc
class ScrollToTop extends Component {
  // eslint-disable-next-line require-jsdoc
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
      const pane = document.querySelector('.ant-layout-content');
      if (pane) pane.scrollTop = 0;
    }
  }

  // eslint-disable-next-line require-jsdoc
  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
