/* eslint-disable camelcase */
import React from 'react';
import './Brokerage.less';
import RawHTML from 'components/RawHTML';
import SignupModalButton from 'components/SignupModalButton';
import PublicPage from 'abstract/PublicPage';

class Brokerage extends PublicPage {
  fields = ['copy_1', 'free_trial_button_text', 'copy_2_header', 'copy_2'];

  prefix = 'customs_brokerage';

  pageContent = () => {
    const {
      copy_1,
      free_trial_button_text,
      copy_2_header,
      copy_2,
    } = this.state.data;
    return (
      <div>
        <RawHTML className="brokerage-top-row" html={copy_1} />
        <SignupModalButton buttonClassName="free-trial-button">
          {free_trial_button_text}
        </SignupModalButton>
        <h3>{copy_2_header}</h3>
        <RawHTML html={copy_2} />
      </div>
    );
  };
}

export default Brokerage;
