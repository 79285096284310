import React from 'react';
import ErrorBoundary from 'components/ErrorBoundary';
import { Route } from 'react-router-dom';

const siteURLs = {
  dev: 'https://localhost:3000',
  test: 'https://app-test-5thqw7hsk8b2w698.inlt.com',
  qa: 'https://app-qa-jzpyqw3gb7zqam4t.inlt.com',
  prod: 'https://app.inlt.com',
};

const siteURL = siteURLs[process.env.REACT_APP_STAGE];

export default ({ path, location, exact }) => (
  <ErrorBoundary key={path}>
    <Route
      exact={exact}
      path={path}
      render={() => {
        window.location.replace(
          `${siteURL}${
            location.pathname === '/dashboard' ? '/' : location.pathname
          }${location.search}`,
        );
        return null;
      }}
    />
  </ErrorBoundary>
);
