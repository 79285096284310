import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import pricingTable from 'libs/pricingTable';
import { formatMoney } from 'accounting';
import { entityTypes } from 'libs/utils';
import './PricingSaved.less';

const PricingSaved = ({ form, entityType }) => {
  const data = form.getFieldsValue();
  const perEntry =
    (data.entryFee || 0) +
    (data.isfFee || 0) +
    (data.pgaFee || 0) +
    (data.lineFee || 0) +
    (data.otherFee || 0);
  const entriesPerYear = data.realEntries || 0;
  const annualSpend = Math.round(perEntry * entriesPerYear);
  const percentSelfFiled = 0;
  const inltPerFullEntry = parseInt(
    pricingTable[entityType].full.plans[data.plan].replace(/\$/, ''),
    10,
  );
  const inltPerSelfEntry =
    entityType === entityTypes.importer
      ? parseInt(
          pricingTable[entityType].self.plans[data.plan].replace(/\$/, ''),
          10,
        )
      : 0;
  const inltPerMonth = parseInt(
    pricingTable[entityType].price.plans[data.plan]
      .replace(/\$/, '')
      .replace(/\/mo\./, ''),
    10,
  );
  const inltPlan = Math.round(
    entriesPerYear * percentSelfFiled * inltPerSelfEntry +
      entriesPerYear * (1 - percentSelfFiled) * inltPerFullEntry +
      12 * inltPerMonth,
  );
  let saved = Math.round(annualSpend - inltPlan);
  let percent = Math.round((100 * saved) / annualSpend) + '%';
  if (saved <= 0) {
    saved = 0;
    percent = '0%';
  }
  return (
    <div>
      <div className="pricing-spend">
        <Row>
          <Col span={12}>
            <p className="spend-title">Current spend</p>
          </Col>
          <Col span={12}>
            <p className="spend-title">Spend with INLT</p>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <p className="spend current">{formatMoney(annualSpend, '$', 0)}</p>
          </Col>
          <Col span={12}>
            <p className="spend inlt">{formatMoney(inltPlan, '$', 0)}</p>
          </Col>
        </Row>
      </div>
      <div className="pricing-saved">
        <div>
          <p className="savings">Savings</p>
          <p className="saved">{formatMoney(saved, '$', 0)}</p>
          <p className="percent">{percent}</p>
        </div>
      </div>
    </div>
  );
};

PricingSaved.propTypes = {
  form: PropTypes.object.isRequired,
  entityType: PropTypes.string.isRequired,
};

export default PricingSaved;
