// temporary fix https://github.com/ant-design/ant-design/issues/12011#issuecomment-423173228

export {
  default as CheckOutline,
} from '@ant-design/icons/lib/outline/CheckOutline';
export {
  default as CloudUploadOutline,
} from '@ant-design/icons/lib/outline/CloudUploadOutline';
export {
  default as DownOutline,
} from '@ant-design/icons/lib/outline/DownOutline';
export {
  default as DownloadOutline,
} from '@ant-design/icons/lib/outline/DownloadOutline';
export {
  default as EditOutline,
} from '@ant-design/icons/lib/outline/EditOutline';
export {
  default as ExclamationCircleOutline,
} from '@ant-design/icons/lib/outline/ExclamationCircleOutline';
export {
  default as FilePdfOutline,
} from '@ant-design/icons/lib/outline/FilePdfOutline';
export {
  default as LinkOutline,
} from '@ant-design/icons/lib/outline/LinkOutline';
export {
  default as LockOutline,
} from '@ant-design/icons/lib/outline/LockOutline';
export {
  default as MailOutline,
} from '@ant-design/icons/lib/outline/MailOutline';
export {
  default as InfoCircleOutline,
} from '@ant-design/icons/lib/outline/InfoCircleOutline';
export {
  default as PictureOutline,
} from '@ant-design/icons/lib/outline/PictureOutline';
export {
  default as PlusOutline,
} from '@ant-design/icons/lib/outline/PlusOutline';
export {
  default as PlusCircleOutline,
} from '@ant-design/icons/lib/outline/PlusCircleOutline';
export {
  default as SaveOutline,
} from '@ant-design/icons/lib/outline/SaveOutline';
export {
  default as TableOutline,
} from '@ant-design/icons/lib/outline/TableOutline';
export {
  default as UserOutline,
} from '@ant-design/icons/lib/outline/UserOutline';
export {
  default as WarningOutline,
} from '@ant-design/icons/lib/outline/WarningOutline';
export {
  default as BellOutline,
} from '@ant-design/icons/lib/outline/BellOutline';
export {
  default as ClockCircleOutline,
} from '@ant-design/icons/lib/outline/ClockCircleOutline';
export {
  default as QuestionCircleOutline,
} from '@ant-design/icons/lib/outline/QuestionCircleOutline';
export {
  default as RocketOutline,
} from '@ant-design/icons/lib/outline/RocketOutline';
export {
  default as SyncOutline,
} from '@ant-design/icons/lib/outline/SyncOutline';
export {
  default as ToolOutline,
} from '@ant-design/icons/lib/outline/ToolOutline';

// export what antd other components need
export {
  default as CloseOutline,
} from '@ant-design/icons/lib/outline/CloseOutline';
export {
  default as LoadingOutline,
} from '@ant-design/icons/lib/outline/LoadingOutline';
export {
  default as CheckCircleOutline,
} from '@ant-design/icons/lib/outline/CheckCircleOutline';
export {
  default as CloseCircleOutline,
} from '@ant-design/icons/lib/outline/CloseCircleOutline';
export {
  default as CheckCircleFill,
} from '@ant-design/icons/lib/fill/CheckCircleFill';
export {
  default as InfoCircleFill,
} from '@ant-design/icons/lib/fill/InfoCircleFill';
export {
  default as CloseCircleFill,
} from '@ant-design/icons/lib/fill/CloseCircleFill';
export {
  default as ExclamationCircleFill,
} from '@ant-design/icons/lib/fill/ExclamationCircleFill';
export { default as UpOutline } from '@ant-design/icons/lib/outline/UpOutline';
export {
  default as LeftOutline,
} from '@ant-design/icons/lib/outline/LeftOutline';
export {
  default as RightOutline,
} from '@ant-design/icons/lib/outline/RightOutline';
export {
  default as RedoOutline,
} from '@ant-design/icons/lib/outline/RedoOutline';
export {
  default as CalendarOutline,
} from '@ant-design/icons/lib/outline/CalendarOutline';
export {
  default as SearchOutline,
} from '@ant-design/icons/lib/outline/SearchOutline';
export {
  default as BarsOutline,
} from '@ant-design/icons/lib/outline/BarsOutline';
export { default as StarFill } from '@ant-design/icons/lib/fill/StarFill';
export {
  default as FilterOutline,
} from '@ant-design/icons/lib/outline/FilterOutline';
export {
  default as CaretUpOutline,
} from '@ant-design/icons/lib/outline/CaretUpOutline';
export {
  default as CaretDownOutline,
} from '@ant-design/icons/lib/outline/CaretDownOutline';
export {
  default as FileOutline,
} from '@ant-design/icons/lib/outline/FileOutline';
export {
  default as FolderOpenOutline,
} from '@ant-design/icons/lib/outline/FolderOpenOutline';
export {
  default as FolderOutline,
} from '@ant-design/icons/lib/outline/FolderOutline';
export {
  default as PaperClipOutline,
} from '@ant-design/icons/lib/outline/PaperClipOutline';
export {
  default as EyeOutline,
} from '@ant-design/icons/lib/outline/EyeOutline';
export {
  default as DeleteOutline,
} from '@ant-design/icons/lib/outline/DeleteOutline';
export {
  default as ShoppingOutline,
} from '@ant-design/icons/lib/outline/ShoppingOutline';
export {
  default as MenuFoldOutline,
} from '@ant-design/icons/lib/outline/MenuFoldOutline';
export {
  default as MenuUnfoldOutline,
} from '@ant-design/icons/lib/outline/MenuUnfoldOutline';
