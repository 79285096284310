import { handleActions } from 'redux-actions';
import { persistReducer, createTransform } from 'redux-persist';
import { combineReducers } from 'redux';
import moment from 'moment';
import signup from './signup';
import localStorage from 'redux-persist/lib/storage';

const isFetching = handleActions(
  {
    FETCH_START: (state, action) => true,

    FETCH_STOP: (state, action) => false,
  },
  false,
);

const safeSignupTransform = createTransform(
  (inboundState) => inboundState,
  (outboundState, key) => {
    if (key === 'captured') {
      return typeof outboundState === 'boolean' ? outboundState : false;
    } else if (key === 'previouslyShown') {
      const previouslyShown = moment(outboundState);
      return previouslyShown.isValid() ? previouslyShown : null;
    } else {
      return outboundState;
    }
  },
);

const signupConfig = {
  key: 'signup',
  storage: localStorage,
  transforms: [safeSignupTransform],
  blacklist: ['signupVisible', 'newsletterVisible'],
};

const trump = handleActions(
  {
    TRUMP_RESPONSE: (state, { payload }) => ({ result: payload }),
  },
  { result: [] },
);

const rootReducer = combineReducers({
  isFetching,
  trump,
  signup: persistReducer(signupConfig, signup),
});

export default rootReducer;
