import React, { Component } from 'react';
import GA from 'react-ga';
import sentryCatch from 'libs/sentryCatch';
import * as Sentry from '@sentry/browser';
import { Button } from 'antd';

class ErrorBoundary extends Component {
  state = { hasError: false };

  componentDidCatch(error, info) {
    if (process.env.REACT_APP_STAGE === 'prod') {
      GA.exception({
        description: error.toString(),
        fatal: true,
      });
    }
    this.setState({ hasError: true, message: error.toString() });
    sentryCatch(error, { level: 'fatal', extra: { info } });
  }

  render() {
    const { hideOnError, children } = this.props;
    if (this.state.hasError) {
      if (hideOnError) return null;
      return (
        <>
          <h1>Sorry, something went wrong.</h1>
          <h2 className="space-bottom">Our team has been notified.</h2>
          <Button
            onClick={() => Sentry.lastEventId() && Sentry.showReportDialog()}
          >
            Click here to fill out a report.
          </Button>
          {process.env.REACT_APP_STAGE !== 'prod' && this.state.message && (
            <p>{this.state.message}</p>
          )}
        </>
      );
    } else {
      return children;
    }
  }
}

export default ErrorBoundary;
