import React from 'react';
import PropTypes from 'prop-types';
import CurrencyField from 'components/CurrencyField';
import { InputNumber, Form, Icon, Tooltip } from 'antd';
import bestPlan from 'libs/bestPlan';
import './PricingFees.less';

const formatNumber = (number) =>
  number && `${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

const feeFields = {
  entryFee: {
    label: 'Entry fee',
    max: 1000,
  },
  lineFee: {
    label: 'Additional line fees',
    max: 1000,
  },
  isfFee: {
    label: (
      <div style={{ display: 'unset' }}>
        <span className="feeLabelTooltip">ISF fee</span>
        <Tooltip
          placement="bottom"
          title="Importer Security Filing, mandatory for ocean freight"
        >
          <Icon type="info-circle" />
        </Tooltip>
      </div>
    ),
    max: 1000,
  },
  pgaFee: {
    label: (
      <div style={{ display: 'unset' }}>
        <span className="feeLabelTooltip">PGA fee</span>
        <Tooltip
          placement="bottom"
          title="Partnering Government Agency, e.g. FDA, EPA, CPCS, USDA"
        >
          <Icon type="info-circle" />
        </Tooltip>
      </div>
    ),
    max: 1000,
  },
  otherFee: {
    label: (
      <div style={{ display: 'unset' }}>
        <span className="feeLabelTooltip">Other fees</span>
        <Tooltip
          placement="bottom"
          title="Who knows what other fees your broker has dreamed up?"
        >
          <Icon type="info-circle" />
        </Tooltip>
      </div>
    ),
    max: 1000,
  },
};

const realEntries = {
  label: 'No. of entries per year',
  max: 50000,
};

const realEntriesLayout = {
  labelCol: { span: 20 },
  wrapperCol: { span: 4 },
};
const currencyFormItemLayout = {
  labelCol: { span: 20 },
  wrapperCol: { span: 4 },
};

const getGoodNumber = (value) => {
  const defaultValue = 0;

  if (!value) return value;

  if (typeof value === 'string') {
    const cleanedValue = value.replace(/^[A-z]+$/g, '');
    const parsedValue = parseInt(cleanedValue, 10);
    return parsedValue ? Math.min(parsedValue, realEntries.max) : defaultValue;
  }

  if (typeof value === 'number')
    return value > realEntries.max ? realEntries.max : value;

  return defaultValue;
};

const handleRealEntriesChange = (value, entityType, form) => {
  const num = getGoodNumber(value);
  const newPlan = bestPlan(entityType, num);
  const plan = form.getFieldValue('plan');
  if (plan !== newPlan) form.setFieldsValue({ plan: newPlan });
  return num;
};

const fees = (form, initialValues) =>
  Object.keys(feeFields).map((field) => (
    <CurrencyField
      key={field}
      form={form}
      label={feeFields[field].label}
      field={field}
      initialValue={initialValues[field]}
      formItemLayout={currencyFormItemLayout}
      max={feeFields[field].max}
    />
  ));

const PricingFees = ({ entityType, form, initialValues }) => (
  <div className="pricing-fees">
    <Form.Item
      label={realEntries.label}
      {...realEntriesLayout}
      className="real-entries"
    >
      {form.getFieldDecorator('realEntries', {
        getValueFromEvent: (value) =>
          handleRealEntriesChange(value, entityType, form),
        initialValue: initialValues.realEntries,
      })(
        <InputNumber
          size="large"
          formatter={(value) => formatNumber(value)}
          parser={(value) => value.replace(/,/g, '')}
          min={0}
          max={realEntries.max}
        />,
      )}
    </Form.Item>
    {fees(form, initialValues)}
  </div>
);

PricingFees.propTypes = {
  form: PropTypes.any.isRequired,
  entityType: PropTypes.any.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default PricingFees;
