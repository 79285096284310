import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Layout, Button, Menu, Icon, Dropdown } from 'antd';
import logoDark from 'assets/logo-black.svg';
import { NEWSLETTER_SHOW, SIGNUP_SHOW } from 'actions';
import * as pages from 'libs/publicPages';
import './Header.less';
import SignupModalButton from 'components/SignupModalButton';
import SignupModal from 'components/SignupModal';
import gaEvent from 'libs/gaEvent';

const { Header } = Layout;
const { services, company } = pages;
const { SubMenu, Divider } = Menu;

const subMenuItems = (store) =>
  Object.keys(store).map((key, index) => (
    <Menu.Item key={index}>
      <Link
        onClick={() => gaEvent('Header', 'Navigation', `${store[key]} Clicked`)}
        className="header-menu-item-link"
        to={key}
      >
        {store[key]}
      </Link>
    </Menu.Item>
  ));

const servicesItems = subMenuItems(services);
const servicesMenu = <Menu>{servicesItems}</Menu>;

const companyItems = subMenuItems(company);
const companyMenu = <Menu>{companyItems}</Menu>;

const switchLink = (
  <Link
    to="/switch"
    className="app-public-header"
    onClick={() => gaEvent('Header', 'Navigation', 'Why Switch? Clicked')}
  >
    Why Switch?
  </Link>
);

// biz wants pricing link to be hidden but still live
//  const pricingLink = (
//    <Link
//      to="/pricing"
//      className="app-public-header"
//      onClick={() => gaEvent('Header', 'Navigation', 'Pricing Clicked')}
//    >
//      Pricing
//    </Link>
//  );

 <Link to="/help" className="app-public-header">Help</Link>

 const knowledgeCenterLink = (
   <Link
   to={{ pathname: "https://help.inlt.com" }} 
   target="_blank"
     className="app-public-header"
     onClick={() => gaEvent('Header', 'Navigation', 'Knowledge Center Clicked')}
   >
     Knowledge Center
   </Link>
 );

const trumpLink = (
  <Link
    to="/section301"
    className="app-public-header"
    onClick={() => gaEvent('Header', 'Navigation', 'Trump Clicked')}
  >
    Section 301
  </Link>
);

class PublicHeader extends Component {
  state = {
    mobileMenuCollapsed: true,
        openKeys: [],
  };

     onOpenChange = (openKeys) => {
       const latestOpenKey = openKeys.find(
         (key) => !this.state.openKeys.includes(key),
       );
       this.setState({
         openKeys: latestOpenKey ? [latestOpenKey] : [],
       });
     };

  onClick = () => {
    this.setState({ mobileMenuCollapsed: true });
  };

  toggleMobileMenu = () => {
    this.setState({
      mobileMenuCollapsed: !this.state.mobileMenuCollapsed,
    });
  };

  handleLoginClick = () => {
    gaEvent('Header', 'Login Button Clicked');
    this.props.history.push('/login');
  };

  closeNewsletterModal = () => {
    this.props.dispatch(NEWSLETTER_SHOW(false));
  };

  closeSignupModal = () => {
    this.props.dispatch(SIGNUP_SHOW(false));
  };

  mobileMenu = () => (
    <div className="mobile-menu">
      <Menu
        style={{ width: '100%' }}
        mode="inline"
        openKeys={this.state.openKeys}
        onOpenChange={this.onOpenChange}
        onClick={this.onClick}
      >
         <SubMenu
           key="services"
           title="Services"
           onTitleClick={() =>
             gaEvent('Header', 'Navigation', 'Services Menu Shown')
           }
         >
           {servicesItems}
         </SubMenu>
         <Divider />
         {/* <Menu.Item key="pricing">{pricingLink}</Menu.Item> */}
         <Menu.Item key="why-inlt">{switchLink}</Menu.Item>
         <Divider />
         <SubMenu
           key="company"
           onTitleClick={() =>
             gaEvent('Header', 'Navigation', 'Company Menu Shown')
           }
           title="Company"
         >
           {companyItems}
         </SubMenu>
         <Divider />
         <Menu.Item key="knowledgeCenter">{knowledgeCenterLink}</Menu.Item>
        {/* <Menu.Item key="trump">{trumpLink}</Menu.Item> */}
      </Menu>
    </div>
  );

  render = () => (
    <div className="app-header-shadow">
      <Header className={'app-header'}>
        <div className={'app-header-wrapped small-width'}>
          <div className="app-header-navigation">
            <Link
              to="/"
              className="app-logo-link"
              onClick={() => gaEvent('Header', 'Logo Clicked')}
            >
              <img src={logoDark} className="app-logo" alt="INLT" />
            </Link>
            <div className="desktop-menu">
              <Dropdown
                onVisibleChange={(visible) =>
                  gaEvent('Header', 'Navigation', 'Services Menu Shown')
                }
                overlay={servicesMenu}
              >
                <span className="app-public-header">
                  Services <Icon type="down" color="info-color" />
                </span>
              </Dropdown>
              {/* {pricingLink} */}
              {switchLink}
              <Dropdown
                overlay={companyMenu}
                onVisibleChange={(visible) =>
                  gaEvent('Header', 'Navigation', 'Company Menu Shown')
                }
              >
                <span className="app-public-header">
                  Company <Icon type="down" />
                </span>
              </Dropdown>
              {knowledgeCenterLink}
              {/* {trumpLink} */}
            </div>
          </div>
          <Button className="mobile-menu" onClick={this.toggleMobileMenu}>
            <Icon
              type={
                this.state.mobileMenuCollapsed ? 'menu-unfold' : 'menu-fold'
              }
            />
          </Button>
          {/* <div className="user-actions">
            <div className="public-actions">
              <Button className="log-in-button" onClick={this.handleLoginClick}>
                Login
              </Button>
              <SignupModalButton
                buttonClassName="sign-up-button"
                type="secondary"
              >
                Start Free Trial
              </SignupModalButton>
            </div>
          </div> */}
        </div>
      </Header>
      {!this.state.mobileMenuCollapsed && this.mobileMenu()}
        <SignupModal
          visible={this.props.newsletterVisible}
          onSubmit={this.closeNewsletterModal}
          onCancel={this.closeNewsletterModal}
          type="newsletter"
        />
        <SignupModal
          visible={this.props.signupVisible}
          onSubmit={this.closeSignupModal}
          onCancel={this.closeSignupModal}
        />
    </div>
  );
}

PublicHeader.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  isFetching: PropTypes.bool,
  newsletterVisible: PropTypes.bool,
};

export default PublicHeader;
