import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import cms from 'libs/butter';
import Footer from 'components/Footer';
import dashboard from 'assets/dashboard.png';
import gaEvent from 'libs/gaEvent';
import { Row, Col, Modal, Carousel, Tabs, Button } from 'antd';
import Loading from 'components/Loading';
// import ReceiveUpdatesForm from 'components/ReceiveUpdatesForm';
import './PublicHome.less';

// const { TabPane } = Tabs;
const headlineLayout = {
  xs: 24,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12,
};

const testimonialPersonalLayout = {
  xs: 12,
  sm: 12,
  md: 4,
  lg: 4,
  xl: 4,
};

const testimonialCopyLayout = {
  xs: 24,
  sm: 24,
  md: 16,
  lg: 16,
  xl: 16,
};

class PublicHome extends Component {
  state = {
    data: {},
    loaded: false,
  };

  componentDidMount = async () => {
    if (!this.state.data.headline) {
      const response = await cms('content', 'retrieve', [
        'headline',
        'subheadline',
        'testimonials',
        'forImporters',
        'forForwarders',
      ]);
      if (response) this.setState({ ...response.data, loaded: true });
    }

    const fromEmailVerified = this.props.location.search.split(
      '?fromEmailVerified=',
    )[1];
    if (fromEmailVerified === 'true') {
      Modal.success({
        title: `Success! ${String.fromCodePoint(0x1f389)}`,
        content:
          'Emails to your customers will now be sent from your email address.',
      });
    } else if (fromEmailVerified === 'false') {
      Modal.error({
        title: `Hmmm... ${String.fromCodePoint(0x1f914)}`,
        content:
          'Something went wrong. Please try again or contact INLT for assistance.',
      });
    }
  };

  handleLinkClick = (path) => {
    this.props.history.push(path);
  };

  render() {
    if (!this.state.loaded) return <Loading isLoading />;
    const {
      headline,
      subheadline,
      testimonials,
      forImporters,
      forForwarders,
    } = this.state.data;

    return (
      <div style={{ display: 'unset' }}>
        <div className="home content" data-test="home">
          <Row gutter={8} type="flex" id="headlines-row" align="middle">
            <div className="headlines-row-wrapped">
              <Col className="dashboard-col" {...headlineLayout} span={12}>
                <img
                  alt="INLT Dashboard"
                  className="home-image"
                  src={dashboard}
                />
              </Col>
              <Col className="headlines-col" {...headlineLayout} span={12}>
                {/*<h1 className="space-bottom">INLT is now an Amazon company</h1>
                <p className="space-bottom">
                  We have been acquired by Amazon and look forward to working
                  with them to develop the next generation of solutions for our
                  current customers and Amazon Selling Partners.
                </p>*/}
                <h2 className="headline">{headline}</h2>
                <h3 className="subheadline">{subheadline}</h3>
                <Button
                  className="demo-button"
                  href="mailto:helloINLT@amazon.com"
                  onClick={() => gaEvent('PublicHome', 'Request A Demo Button Clicked')}
                >
                  Schedule a demo
                </Button>
              </Col>
            </div>
          </Row>
          {/*<div className="home-row receive-updates-row">
            <p className="space-bottom new-customer-statement">
              We are not accepting new customers at this time, but please sign
              up below if you are interested in receiving future updates.
            </p>
            <ReceiveUpdatesForm />
          </div>*/}
          <div className="home-row" id="testimonials">
            {testimonials && (
              <Carousel autoplay autoplaySpeed={8000}>
                {testimonials.map(
                  ({ name, title, company, testimonial, logo }, i) => (
                    <Row
                      key={i}
                      type="flex"
                      align="middle"
                      className="testimonials-row"
                    >
                      <Col
                        {...testimonialPersonalLayout}
                        className="testimonials-col"
                      >
                        <img alt={`${company} logo`} src={logo} />
                      </Col>
                      <Col
                        {...testimonialPersonalLayout}
                        className="testimonials-col"
                      >
                        <h2 className="testimonial-name">{name}</h2>
                        <h3>{title}</h3>
                        <h3>{company}</h3>
                      </Col>
                      <Col {...testimonialCopyLayout}>
                        <p>{testimonial}</p>
                      </Col>
                    </Row>
                  ),
                )}
              </Carousel>
            )}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(PublicHome);
