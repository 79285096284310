import React from 'react';
import PropTypes from 'prop-types';
import PricingForm from 'components/PricingForm';
import { entityTypes } from 'libs/utils';
import './NvoPricing.less';
import PublicPage from 'abstract/PublicPage';
import FAQ from 'components/FAQ';

class NvoPricing extends PublicPage {
  fields = ['faq'];

  prefix = 'nvo_pricing';

  pageContent = () => {
    const { faq } = this.state.data;

    return (
      <div className="nvo-pricing-wrapper">
        <PricingForm entityType={entityTypes.forwarder} />
        <h2>Frequently Asked Questions</h2>
        <FAQ faqs={faq} />
      </div>
    );
  };
}

NvoPricing.propTypes = {
  history: PropTypes.any.isRequired,
};

export default NvoPricing;
