import React from 'react';
import PropTypes from 'prop-types';
import { Form, Col, Row } from 'antd';
import PricingGrid from 'components/PricingGrid';
import PricingEntries from 'components/PricingEntries';
import PricingFees from 'components/PricingFees';
import PricingSaved from 'components/PricingSaved';
import './PricingForm.less';
import defaults from './defaults';
import gaEvent from 'libs/gaEvent';

const colLayout = {
  xs: 24,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12,
};

const PricingForm = ({ entityType, form }) => {
  const { plan, entries, ...otherDefaults } = defaults[entityType];
  return (
    <div className="pricing-form content-center">
      <PricingEntries
        entityType={entityType}
        form={form}
        initialValue={entries}
      />
      <PricingGrid entityType={entityType} form={form} initialValue={plan} />
      {/* <h2 className="pricing-contact">
        Click{' '}
        <a
          onClick={() => gaEvent('Pricing', 'Contact Us Link Clicked')}
          href="mailto:hello@inlt.com"
        >
          here
        </a>{' '}
        to contact us
      </h2> */}
      <h2 className="pricing-calculator">
        How much can switching save you?
        <br />
        Enter your current rates to find out.
      </h2>
      <Row className="space-top savings" gutter={16}>
        <Col {...colLayout}>
          <PricingFees
            form={form}
            entityType={entityType}
            initialValues={otherDefaults}
          />
        </Col>
        <Col {...colLayout}>
          <PricingSaved form={form} entityType={entityType} />
        </Col>
      </Row>
    </div>
  );
};

PricingForm.propTypes = {
  form: PropTypes.object.isRequired,
  entityType: PropTypes.string.isRequired,
};

export default Form.create()(PricingForm);
