import * as Sentry from '@sentry/browser';
import GA from 'react-ga';

export default (e, options = {}) => {
  if (process.env.REACT_APP_STAGE === 'prod') {
    GA.exception({
      description: e.message,
    });
  } else {
    console.log(`Sentry caught:`, e); // eslint-disable-line no-console
  }
  Sentry.withScope((scope) => {
    const { user, level, tags, fingerprint, extra } = options;
    if (user) {
      scope.setUser(user);
    }
    if (level) {
      scope.setLevel(level);
    }
    if (tags) {
      Object.entries(tags).forEach(([key, val]) => {
        scope.setTag(key, val);
      });
    }
    if (fingerprint) {
      scope.setFingerprint(fingerprint);
    }
    if (extra) {
      Object.entries(extra).forEach(([key, val]) => {
        scope.setExtra(key, val);
      });
    }
    Sentry.captureException(e);
  });
};
