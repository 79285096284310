import GA from 'react-ga';

export default (categoryRaw, actionRaw, labelRaw, valueRaw) => {
  const category = categoryRaw && categoryRaw.toString();
  const action = actionRaw && actionRaw.toString();
  const label = labelRaw && labelRaw.toString();
  const value = valueRaw;

  GA.event({ category, action, label, value });
};
