import React from 'react';
import { Row, Col } from 'antd';
import RawHTML from 'components/RawHTML';
import './Contact.less';
import PublicPage from 'abstract/PublicPage';

class Contact extends PublicPage {
  fields = ['copy', 'cities'];

  prefix = 'contact';

  pageContent = () => {
    const { copy, cities } = this.state.data;

    const citiesJSX = cities.map((city, i) => (
      <Col key={i} span={Math.floor(24 / cities.length)}>
        <h2>{city.name}</h2>
        <RawHTML html={city.address} />
      </Col>
    ));

    return (
      <div>
        <div>
          <RawHTML html={copy} />
          <Row gutter={16} className="location">
            {citiesJSX}
          </Row>
        </div>
      </div>
    );
  };
}

export default Contact;
