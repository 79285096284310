/* eslint-disable camelcase */
import React from 'react';
import SwitchGrid from 'components/SwitchGrid';
import RawHTML from 'components/RawHTML';
import './Switch.less';
import PublicPage from 'abstract/PublicPage';

class Switch extends PublicPage {
  fields = ['copy_1', 'traditional_title', 'inlt_title', 'table', 'copy_2'];

  prefix = 'switch';

  pageContent = () => {
    const {
      copy_1,
      copy_2,
      traditional_title,
      inlt_title,
      table,
    } = this.state.data;
    return (
      <div className="switch">
        <RawHTML html={copy_1} />
        <SwitchGrid
          traditionalTitle={traditional_title}
          inltTitle={inlt_title}
          table={table}
        />
        <RawHTML html={copy_2} />
      </div>
    );
  };
}

export default Switch;
