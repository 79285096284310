import React, { Component } from 'react';
import RawHTML from 'components/RawHTML';
import './SwitchGrid.less';

class SwitchGrid extends Component {
  keyGenerator = (columnName, index) => `${columnName}-${index}`;

  rows = this.props.table.map((row, index) => {
    const inltHeader = <h2 className="switch-grid-title">{row.inlt_header}</h2>;
    const traditionalHeader = (
      <h2 className="switch-grid-title">{row.traditional_header}</h2>
    );
    const inltText = (
      <RawHTML className="switch-grid-text" html={row.inlt_text} />
    );
    const traditionalText = (
      <RawHTML className="switch-grid-text" html={row.traditional_text} />
    );
    return (
      <tr key={index} className="switch-grid-row">
        <td key={this.keyGenerator('traditional', index)}>
          {traditionalHeader}
          {traditionalText}
        </td>
        <td key={this.keyGenerator('inlt', index)}>
          {inltHeader}
          {inltText}
        </td>
      </tr>
    );
  });

  render() {
    const { traditionalTitle, inltTitle } = this.props;
    return (
      <div className="switch-grid">
        <table>
          <thead>
            <tr className="switch-grid-header">
              <th>
                <h2>{traditionalTitle}</h2>
              </th>
              <th>
                <h2>{inltTitle}</h2>
              </th>
            </tr>
          </thead>
          <tbody>{this.rows}</tbody>
        </table>
      </div>
    );
  }
}

export default SwitchGrid;
