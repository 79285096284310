export default [
  '/billing',
  '/billing/:id',
  'search',
  '/dashboard',
  '/help',
  '/shipments',
  '/shipments/:id',
  '/products',
  '/products/upload',
  '/products/:id',
  '/classifications',
  '/classifications/:id',
  '/forwarders',
  '/forwarders/:id',
  '/destinations',
  '/destinations/:id',
  '/truckers',
  '/truckers/:id',
  '/suppliers',
  '/suppliers/upload',
  '/suppliers/:id',
  '/importers',
  '/importers/:id',
  '/users',
  '/users/:id',
  '/profile',
  '/company',
  '/questionnaire',
  '/login',
];
