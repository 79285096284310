/**
 * promisified setTimeout
 * @module
 * @param {Number} ms
 * @return {Promise}
 */
export default (ms) =>
  new Promise((resolve) => {
    const id = setTimeout(() => {
      clearTimeout(id);
      resolve();
    }, ms);
    return id;
  });
