import React from 'react';
import { withRouter } from 'react-router';
import GA from 'react-ga';
import './NotFound.less';

class NotFound extends React.Component {
  componentDidMount = () => {
    GA.exception({
      description: 'Page not found',
    });
  };

  render = () => (
    <div className="not-found content">
      <div className="content-header">
        <h1>Sorry, page not found!</h1>
      </div>
      <div className="content-main" />
    </div>
  );
}

export default withRouter(NotFound);
