import React from 'react';
import PropTypes from 'prop-types';
import { Form, Slider } from 'antd';
import bestPlan from 'libs/bestPlan';
import './PricingEntries.less';
import gaEvent from 'libs/gaEvent';

const sliderProps = {
  min: 10,
  max: 500,
  step: 10,
  marks: {
    10: '10',
    500: '500+',
  },
};

const PricingEntries = ({ entityType, form, initialValue }) => {
  const handleEntriesChange = (val) => {
    gaEvent('Pricing', 'Entries Slider Moved');
    const newPlan = bestPlan(entityType, val);
    const plan = form.getFieldValue('plan');
    if (newPlan !== plan) form.setFieldsValue({ plan: newPlan });
  };

  const { getFieldDecorator } = form;
  const formItemLayout = {
    labelCol: { xs: 24, sm: 24, md: 10, lg: 10, xl: 10 },
    wrapperCol: { xs: 24, sm: 24, md: 14, lg: 14, xl: 14 },
  };

  return (
    <Form.Item
      {...formItemLayout}
      className="pricing-entries"
      label="No. of entries per year"
    >
      {getFieldDecorator('entries', { initialValue })(
        <Slider onChange={handleEntriesChange} {...sliderProps} />,
      )}
    </Form.Item>
  );
};

PricingEntries.propTypes = {
  form: PropTypes.any.isRequired,
  entityType: PropTypes.string.isRequired,
  initialValue: PropTypes.number.isRequired,
};

export default PricingEntries;
