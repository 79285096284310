import { createAction } from 'redux-actions';
import { Apig } from 'libs/apig';
import { message } from 'antd';
import { Signup } from 'libs/queries';
import networkError from 'libs/networkError';
import sentryCatch from 'libs/sentryCatch';
import gaEvent from 'libs/gaEvent';

export const SIGNUP_SHOW = createAction('SIGNUP_SHOW');
export const NEWSLETTER_SHOW = createAction('NEWSLETTER_SHOW');
export const SIGNUP_CAPTURE = createAction('SIGNUP_CAPTURE');

export const SIGNUP = (values) => async (dispatch) => {
  try {
    await Apig.graphql({
      path: '/graphql',
      query: Signup,
      variables: { input: values },
    });
    // message.success('Thanks for signing up! An email was sent to activate your account.', 5.0);
    if (values.type === 'newsletter') {
      message.success('Thanks for signing up!', 5.0);
      gaEvent('Signup', 'Newsletter');
    } else {
      message.success(
        'Thanks for signing up! We sent you an email with a link to begin onboarding.',
        5.0,
      );
      gaEvent('Signup', 'Customer');
    }
  } catch (e) {
    networkError(e);
    sentryCatch(e);
  }
};
