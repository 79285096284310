import { unMomentize } from 'libs/momentize';

const getFullFormKeys = ({ values, arr = [], prefix = '', whitelist = [] }) => {
  if (!values) return [];
  const cleanValues = unMomentize(values);
  let isArray = false;
  if (prefix) {
    if (Array.isArray(cleanValues)) {
      isArray = true;
      // we used to just check the value at index 0 here, but that turned out to be insufficient for the case of formatFormErrors where the antd form returns arrays with undefined until the element that actually has an error.
      if (cleanValues.some((value) => typeof value === 'object')) {
        prefix = `${prefix}[`;
      } else {
        // don't go into arrays of scalars.
        arr.push(prefix);
        return arr;
      }
    } else {
      prefix = `${prefix}.`;
    }
  }

  Object.entries(cleanValues).forEach(([key, value]) => {
    let fullKey = `${prefix}${key}`;
    if (isArray) fullKey = `${fullKey}]`;
    if (
      typeof value === 'object' &&
      value !== null &&
      !whitelist.some((regex) => fullKey.match(regex))
    ) {
      getFullFormKeys({ values: value, arr, prefix: fullKey, whitelist });
    } else {
      arr.push(fullKey);
    }
  });
  return arr;
};

export default getFullFormKeys;
