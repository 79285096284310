import { connect } from 'react-redux';
import { SIGNUP_SHOW } from 'actions';
import SignupModalButton from './component';
import gaEvent from 'libs/gaEvent';

const mapDispatchToProps = (dispatch) => ({
  openModal: () => {
    gaEvent('Header', 'Free Trial Button Clicked');
    dispatch(SIGNUP_SHOW(true));
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(SignupModalButton);
