import React from 'react';
import './BasicPublicPage.less';
import RawHTML from 'components/RawHTML';
import PublicPage from 'abstract/PublicPage';

class BasicPublicPage extends PublicPage {
  fields = ['copy'];

  pageContent = () => {
    const { copy } = this.state.data;
    return <RawHTML html={copy} />;
  };
}

export default BasicPublicPage;
