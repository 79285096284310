export const OwnerInfo = `query { ownerInfo { logoUrl entityId tabTitle } }`;
export const LogoUploadURL =
  'query LogoUploadURL($fileName: String!, $contentType: String!) { logoUploadURL(fileName: $fileName, contentType: $contentType) { url } }';
export const AvatarUploadURL =
  'query AvatarUploadURL($fileName: String!, $contentType: String!) { avatarUploadURL(fileName: $fileName, contentType: $contentType) { url } }';
export const Login = `query Login($sub: String!) { login(sub: $sub) }`;
export const Search = `query Search($query: String!) { search(query: $query) { results } }`;
export const Trump = `query Trump($input: String!) { trump(input: $input) { result } }`;
export const GetABI = `query GetABI($objectType: ObjectType!, $objectId: ID!) { getABI(objectType: $objectType, objectId: $objectId) }`;
export const GetAssociatedRecords = `query GetAssociatedRecords($objectType: ObjectType!, $objectId: ID!) { getAssociatedRecords(objectType: $objectType, objectId: $objectId) }`;
export const GetShipmentNotes = `query GetShipmentNotes($shipmentId: ID!) { getShipmentNotes(shipmentId: $shipmentId) }`;
export const GetShipmentScrapers = `query GetShipmentScrapers($shipmentId: ID!) { getShipmentScrapers(shipmentId: $shipmentId) }`;
export const GetSign = `query GetSign($id: ID!) { getSign(id: $id) }`;
export const GetOnboarding = 'query { getOnboarding }';
export const GetQuestionnaire = `query GetQuestionnaire($importerId: ID!) { getQuestionnaire(importerId: $importerId) }`;
export const SpreadsheetPreview = `query SpreadsheetPreview($input: SpreadsheetPreviewInput!) { spreadsheetPreview(input: $input) { items submitted added skipped overwritten merged warnings itemsWithDuplicates } }`;
export const MapShipmentInvoice = `mutation MapShipmentInvoice($input: MapShipmentInvoiceInput!) { mapShipmentInvoice(input: $input) { success } }`;
export const DownloadDoc =
  'query DownloadDoc($input: DownloadDocInput!) { downloadDoc(input: $input) { url } }';
export const DownloadAllShipmentDocs =
  'query DownloadAllShipmentDocs($input: DownloadAllShipmentDocsInput!) { downloadAllShipmentDocs(input: $input) { success } }';
export const Changelog = `query Changelog($objectId: ID!) {
  changelog(objectId: $objectId){
    createdTime
    userName
    action
    userId
    teamOnly
  }
}`;
export const UsersEmail = `query UsersEmail($userId: ID!) { usersEmail(userId: $userId) }`;
const htsPayload = `
    version
    text
    description
    additionalHTS
    additionalHTSNumber
    quota
    ad
    cv
    startDate
    endDate
    licenseCode
    licenseName
    textile
    fees {
      name
      class
      rateCode
      rateString
      required
      rate {
        adValorem
        specific
        other
      }
    }
    rate {
      adValorem
      specific
      other
    }
    uom
    rateCode
    rateString
    spi {
      code
      name
      country
      group
    }
    gspExcluded
    pga {
      flag
      agencyCode
      programCodes
      description
      required
      disclaimerCodes
      likelyDisclaimerCode
      likelyProgramCode
      likelyProcessingCode
    }
    trumpList
    restrictions {
      countryCode
      quantityCode
      quantityLowBounds
      quantityHighBounds
      valueCode
      valueLowBounds
      valueHighBounds
    }
    additional {
      type
      number
      include
      exclude
      hts {
        rateString
      }
    }
    `;
export const HTSId = `query HTSId($id: ID!) {
  htsId(id: $id) {
    ${htsPayload}
  }
}`;
export const HTSNumber = `query HTSNumber($number: String!) {
  htsNumber(number: $number) {
    ${htsPayload}
  }
}`;
export const Dashboard = `query Dashboard($input: DashboardInput!) { dashboard(input: $input) { data name } }`;

export const LinkAction = `mutation LinkAction($hash: String!) { linkAction(hash: $hash) { type shipmentId userName } }`;

export const UpdateCompany = `mutation UpdateCompany($objectType: ObjectType!, $objectId: ID!, $input: PropsInput!) { updateCompany(objectType: $objectType, objectId: $objectId, input: $input) { success } }`;

export const RequestBondPublic = `mutation RequestBondPublic($input: RequestBondPublicInput!) { requestBondPublic(input: $input) { success } }`;

export const UpdatePOC = `mutation UpdatePOC($input: UpdatePOCInput!) { updatePOC(input: $input) { success } }`;
export const Signup = `mutation Signup($input: SignupInput!) { signup(input: $input) { loginLink email } }`;
export const CreateRecords = `mutation CreateRecords($input: CreateRecordsInput!) { createRecords(input: $input) }`;
export const UpdateRecord = `mutation UpdateRecord($input: UpdateRecordInput!) { updateRecord(input: $input) { success } }`;
export const DeleteRecord = `mutation DeleteRecord($input: DeleteRecordInput!) { deleteRecord(input: $input) { success } }`;

export const CreateUserRecord = `mutation CreateUserRecord($input: CreateUserRecordInput!) { createUserRecord(input: $input) { userId } }`;
export const UpdateUserRecord = `mutation UpdateUserRecord($userId: ID!, $input: UpdateUserRecordInput!) { updateUserRecord(userId: $userId, input: $input) { success } }`;
export const DeleteUserRecord = `mutation DeleteUserRecord($userId: ID!) { deleteUserRecord(userId: $userId) { success } }`;

export const UpdateProfile = `mutation UpdateProfile($input: UpdateProfileInput!) { updateProfile(input: $input) { success } }`;
export const SwitchRole = `mutation SwitchRole($entityId: ID) { switchRole(entityId: $entityId) { success } }`;

export const PublishNotification = `mutation PublishNotification($message: String!) { publishNotification(message: $message) { success } }`;

export const Classify = `mutation Classify($input: ClassifyInput!) { classify(input: $input) { success } }`;

export const Sign = `mutation Sign($input: SignInput!) { sign(input: $input) { id cc } }`;

export const SaveOnboarding = `mutation SaveOnboarding($input: JSON!) { saveOnboarding(input: $input) { success } }`;
export const CompleteOnboarding = `mutation CompleteOnboarding($input: JSON!) { completeOnboarding(input: $input) { success } }`;

export const SaveQuestionnaire = `mutation SaveQuestionnaire($importerId: ID!, $input: JSON!) { saveQuestionnaire(importerId: $importerId, input: $input) { success } }`;

export const UpdateDoc = `mutation UpdateDoc($input: UpdateDocInput!) { updateDoc(input: $input) { success } }`;
export const PreviewDoc = `query PreviewDoc($input: PreviewDocInput!) { previewDoc(input: $input) { url } }`;
export const DeleteDoc =
  'mutation DeleteDoc($input: DeleteDocInput!) { deleteDoc(input: $input) { success } }';
export const RejectDoc =
  'mutation RejectDoc($input: RejectDocInput!) { rejectDoc(input: $input) { createdByName createdByEmail } }';
export const InquireClassification =
  'mutation InquireClassification($input: InquireClassificationInput!) { inquireClassification(input: $input) { createdByName createdByEmail } }';
export const UploadDocs =
  'mutation UploadDocs($input: UploadDocsInput!) { uploadDocs(input: $input) }';
export const IssueDO =
  'mutation IssueDO($input: IssueDOInput!) { issueDO(input: $input) { success } }';
export const GiveImporterAccess =
  'mutation GiveImporterAccess($importerId: ID!, $input: GiveImporterAccessInput!) { giveImporterAccess(importerId: $importerId, input: $input) }';
export const ActivateImporter =
  'mutation ActivateImporter($importerId: ID!) { activateImporter(importerId: $importerId) }';
export const Terminate =
  'mutation Terminate($entityId: ID!) { terminate(entityId: $entityId) { success } }';
export const GetTerminationData =
  'query GetTerminationData($entityId: ID!) { getTerminationData(entityId: $entityId) { stats terminateImporters { id name } } }';
export const GetPOALink =
  'query GetPOALink($poaMethod: String!) { getPOALink(poaMethod: $poaMethod) }';
export const ChargeImporterBond =
  'mutation ChargeImporterBond($importerId: ID!) { chargeImporterBond(importerId: $importerId) }';
export const RequestImporterBond =
  'mutation RequestImporterBond($importerId: ID!, $input: RequestImporterBondInput!) { requestImporterBond(importerId: $importerId, input: $input) }';
export const SyncImporter =
  'mutation SyncImporter($importerId: ID!) { syncImporter(importerId: $importerId) { success } }';
export const RequestImporterCBPNumber =
  'mutation RequestImporterCBPNumber($importerId: ID!, $input: RequestImporterCBPNumberInput!) { requestImporterCBPNumber(importerId: $importerId, input: $input) { success } }';
export const AddImporterSignLink =
  'mutation AddImporterSignLink($importerId: ID!, $input: AddImporterSignLinkInput!) { addImporterSignLink(importerId: $importerId, input: $input) }';

export const CreateShipment =
  'mutation CreateShipment ($input: CreateShipmentInput!) { createShipment(input: $input) { shipmentId duplicateBills { number mot shipmentId type user createdTime } } }';
export const PostShipmentNote = `mutation PostShipmentNote($input: PostShipmentNoteInput!) { postShipmentNote(input: $input) { success } }`;
export const GenerateEntryNumber =
  'mutation GenerateEntryNumber($shipmentId: ID!) { generateEntryNumber(shipmentId: $shipmentId) { entryNumber, brokerNumber } }';
export const AddTeamEvent =
  'mutation AddTeamEvent($shipmentId: ID!, $input: AddTeamEventInput!) { addTeamEvent(shipmentId: $shipmentId, input: $input) { success } }';
export const SkipEntry =
  'mutation SkipEntry($shipmentId: ID!, $input: SkipEntryInput!) { skipEntry(shipmentId: $shipmentId, input: $input) { success } }';
export const SkipISF =
  'mutation SkipISF($shipmentId: ID!) { skipISF(shipmentId: $shipmentId) { success } }';
export const UnskipISF =
  'mutation UnskipISF($shipmentId: ID!) { unskipISF(shipmentId: $shipmentId) { success } }';
export const SubmitISF =
  'mutation SubmitISF($shipmentId: ID!, $input: SubmitISFInput!) { submitISF(shipmentId: $shipmentId, input: $input) }';
export const UpdateISF =
  'mutation UpdateISF($shipmentId: ID!, $input: PropsInput!) { updateISF(shipmentId: $shipmentId, input: $input) }';
export const ReplaceRelease =
  'mutation ReplaceRelease($shipmentId: ID!, $input: PropsInput!) { replaceRelease(shipmentId: $shipmentId, input: $input) { success } }';
export const UpdateShipmentBill =
  'mutation UpdateShipmentBill($shipmentId: ID!, $input: PropsInput!) { updateShipmentBill(shipmentId: $shipmentId, input: $input) { success } }';
export const UpdatePGA =
  'mutation UpdatePGA($shipmentId: ID!, $input: PropsInput!) { updatePGA(shipmentId: $shipmentId, input: $input) { success } }';
export const UpdateEntry =
  'mutation UpdateEntry($shipmentId: ID!, $input: UpdateEntryInput!) { updateEntry(shipmentId: $shipmentId, input: $input) { success } }';
export const LockCharges =
  'mutation LockCharges($shipmentId: ID!, $input: PropsInput!) { lockCharges(shipmentId: $shipmentId, input: $input) { success } }';
export const PostCharges =
  'mutation PostCharges($shipmentId: ID!, $input: PropsInput!) { postCharges(shipmentId: $shipmentId, input: $input) { success } }';
export const SubmitShipmentDocs =
  'mutation SubmitShipmentDocs($shipmentId: ID!, $input: SubmitShipmentDocsInput!) { submitShipmentDocs(shipmentId: $shipmentId, input: $input) { success } }';
export const RequestHotShipment =
  'mutation RequestHotShipment($shipmentId: ID!) { requestHotShipment(shipmentId: $shipmentId) { success } }';
export const TagForwarder =
  'mutation TagForwarder($shipmentId: ID!, $tagForwarderId: ID!) { tagForwarder(shipmentId: $shipmentId, tagForwarderId: $tagForwarderId) { success } }';
export const UntagForwarder =
  'mutation UntagForwarder($shipmentId: ID!, $untagForwarderId: ID!) { untagForwarder(shipmentId: $shipmentId, untagForwarderId: $untagForwarderId) { success } }';
export const SaveShipment =
  'mutation SaveShipment($shipmentId: ID!, $input: PropsInput!) { saveShipment(shipmentId: $shipmentId, input: $input) { success } }';
export const CancelShipment =
  'mutation CancelShipment($shipmentId: ID!, $input: CancelShipmentInput!) { cancelShipment(shipmentId: $shipmentId, input: $input) { success } }';
export const ChangeMOT =
  'mutation ChangeMOT($shipmentId: ID!, $mot: String!) { changeMOT(shipmentId: $shipmentId, mot: $mot) { success } }';
export const MergeISF =
  'mutation MergeISF($shipmentId: ID!, $replacementShipmentId: ID!) { mergeISF(shipmentId: $shipmentId, replacementShipmentId: $replacementShipmentId) { newEvents } }';
export const SubmitEntry =
  'mutation SubmitEntry($shipmentId: ID!, $input: SubmitEntryInput!) { submitEntry(shipmentId: $shipmentId, input: $input) {success} }';
export const PreEntryCheck = `query PreEntryCheck($shipmentId: ID!, $input: PropsInput!) { preEntryCheck(shipmentId: $shipmentId, input: $input) {
    confirmationRequired
    needsSingleEntryBond
    stbAmount
    riskType
    warnings {
      type
      string
    }
    totalValue
    dutiesFees
    adDuty
    cvDuty
    amendment {
      title
      message
      oldDutiesFees
      newDutiesFees
    }
    entryType
  }
}`;
export const SaveClientNumber =
  'mutation SaveClientNumber($shipmentId: ID!, $clientNumber: String!) { saveClientNumber(shipmentId: $shipmentId, clientNumber: $clientNumber) { success } }';
export const AmendInvoice =
  'mutation AmendInvoice($id: ID!, $reasonCode: String!) { amendInvoice(id: $id, reasonCode: $reasonCode) { success } }';
export const SaveInvoice =
  'mutation SaveInvoice($invoiceId: ID!, $input: SaveInvoiceInput!) { saveInvoice(invoiceId: $invoiceId, input: $input) { success } }';
export const ResetImporterConfirmation =
  'mutation ResetImporterConfirmation($importerIds: [ID!]!) { resetImporterConfirmation(importerIds: $importerIds) { success } }';
export const ResendFromEmail =
  'mutation ResendFromEmail($forwarderId: ID!) { resendFromEmail(forwarderId: $forwarderId) { success } }';
export const FetchFromEmail =
  'query FetchFromEmail($forwarderId: ID!) { fetchFromEmail(forwarderId: $forwarderId) }';
export const FetchCustomDomain =
  'query FetchCustomDomain($forwarderId: ID!) { fetchCustomDomain(forwarderId: $forwarderId) }';
export const DownloadTable =
  'query DownloadTable($input: DownloadTableInput!) { downloadTable(input: $input) }';
export const FetchTableFilterOptions =
  'query FetchTableFilterOptions($input: FetchTableFilterOptions!) { fetchTableFilterOptions(input: $input) }';
export const tableFilterOptions =
  'query tableFilterOptions($input: FetchTableFilterOptions!) { tableFilterOptions(input: $input) }';
export const SubmitFromEmail =
  'mutation SubmitFromEmail($forwarderId: ID!, $input: SubmitFromEmailInput) { submitFromEmail(forwarderId: $forwarderId, input: $input) }';
export const SubmitCustomDomain =
  'mutation SubmitCustomDomain($forwarderId: ID!, $input: SubmitCustomDomainInput!) { submitCustomDomain(forwarderId: $forwarderId, input: $input) }';
export const AddInBond =
  'mutation AddInBond($shipmentId: ID!) { addInBond(shipmentId: $shipmentId) { success } }';
export const InBondAction =
  'mutation InBondAction($shipmentId: ID!, $inBondIndex: Int!, $input: PropsInput!) { inBondAction(shipmentId: $shipmentId, inBondIndex: $inBondIndex, input: $input) { success } }';
export const SubmitInBond =
  'mutation SubmitInBond($shipmentId: ID!, $inBondIndex: Int!, $input: PropsInput!) { submitInBond(shipmentId: $shipmentId, inBondIndex: $inBondIndex, input: $input) { inBondNumber } }';
export const SubmitInBondBill =
  'mutation SubmitInBondBill($shipmentId: ID!, $inBondIndex: Int!, $inBondBillIndex: Int!, $input: PropsInput!) { submitInBondBill(shipmentId: $shipmentId, inBondIndex: $inBondIndex, inBondBillIndex, $inBondBillIndex, input: $input) { success } }';
export const UpdateInBond =
  'mutation UpdateInBond($shipmentId: ID!, $inBondIndex: Int!, $input: PropsInput!) { updateInBond(shipmentId: $shipmentId, inBondIndex: $inBondIndex, input: $input) { success } }';
export const DeleteInBond =
  'mutation DeleteInBond($shipmentId: ID!, $inBondIndex: Int!, $input: PropsInput!) { deleteInBond(shipmentId: $shipmentId, inBondIndex: $inBondIndex, input: $input) { success } }';
export const DeleteInBondBill =
  'mutation DeleteInBondBill($shipmentId: ID!, $inBondNumber: ID!, $inBondBillNumber: $ID!, $input: PropsInput!) { deleteInBondBill(shipmentId: $shipmentId, inBondNumber: $inBondNumber, inBondBillNumber: $inBondBillNumber, input: $input) { success } }';
export const CopyFromShipment =
  'mutation CopyFromShipment($input: CopyFromShipmentInput!) { copyFromShipment(input: $input) { success } }';
export const LastSeenUpdate = `mutation LastSeenUpdate { lastSeenUpdate { success } }`;
export const AssignShipment = `mutation AssignShipment($input: AssignShipmentInput!) { assignShipment(input: $input) { success } }`;
export const SignupForUpdates = `
  mutation SignupForUpdates($input: SignupForUpdatesInput!) {
    signupForUpdates(input: $input) {
      success
    }
  }
`;
