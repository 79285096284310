import React from 'react';
import './Press.less';
import PublicPage from 'abstract/PublicPage';
import downloadURL from 'libs/downloadURL';
import { Card } from 'antd';

class Press extends PublicPage {
  fields = ['images'];

  prefix = 'press';

  handleClick = (fileURL, fileName) => {
    downloadURL(fileURL, fileName);
  };

  pageContent = () => {
    const { images } = this.state.data;
    return (
      <div className="press-cards">
        {images.map((image) => (
          <Card
            key={image.title}
            actions={[
              <div
                className="download-button"
                onClick={() => this.handleClick(image.url, image.title)}
              >
                Download
              </div>,
            ]}
          >
            <img
              onClick={() => this.handleClick(image.url, image.title)}
              src={image.url}
              alt="INLT-logo"
            />
          </Card>
        ))}
      </div>
    );
  };
}

export default Press;
