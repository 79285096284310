import React from 'react';
import PropTypes from 'prop-types';
import './LabelAbove.less';

/**
 * Label above form inputs
 * @param {Object} form @deprecated Not necessary, kept for legacy until we clean up its usage.
 * @param {String} field @deprecated Not necessary
 * @param {Boolean} alwaysShow if you want to always show the label, even if it's empty.
 * @param {String} Object Text inside label
 * @return {Object}
 */
const LabelAbove = ({ form, alwaysShow, field, text }) => {
  const val = form ? form.getFieldValue(field) : null;
  const isFilled =
    val !== null && typeof val !== 'undefined' && val.toString().length > 0;
  return (
    <p
      className={
        isFilled || alwaysShow
          ? 'form-item-label-above'
          : 'form-item-label-above invisible'
      }
      title={typeof text === 'string' ? text : undefined}
    >
      {text}
    </p>
  );
};

LabelAbove.propTypes = {
  alwaysShow: PropTypes.bool.isRequired,
  form: PropTypes.any,
  field: PropTypes.string,
  text: PropTypes.any.isRequired,
};

LabelAbove.defaultProps = {
  alwaysShow: true,
};

export default LabelAbove;
