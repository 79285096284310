import React from 'react';
import attribution from 'assets/attribution.txt';
import './style.less';

const Attributions = () => (
  <a
    className="attributions"
    target="blank"
    rel="noopener noreferrer"
    href={attribution}
  >
    Attributions
  </a>
);

export default Attributions;
