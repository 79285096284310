import {
  initButter,
  initGA,
  initSentry,
  initLinkedIn,
  initIntercom,
} from 'libs/initService';

export default () => {
  initButter();
  // initGA();
  initSentry();
  initLinkedIn();
  initIntercom();
};
