export const services = {
  '/brokerage': 'Customs Brokerage',
  '/bonds': 'Customs Bonds',
  '/classification': 'Product Classification',
  // '/compliance': 'Compliance Audits',
  // '/manifest-confidentiality': 'Manifest Confidentiality',
  // '/logistics-consulting': 'Logistics Consulting',
  '/pro-services': 'Professional Services',
  // '/isa': 'Importer Self-Assessment',
  // '/ctpat': 'C-TPAT Certification',
};

export const company = {
  '/about': 'About Us',
  '/contact': 'Contact',
  // '/careers': 'Careers',
};
