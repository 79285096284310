import pricingTable from 'libs/pricingTable';
const parseEntryCost = (entityType, num) =>
  parseInt(pricingTable[entityType].full.plans[num].replace(/\$/, ''), 10);
const parseSubscriptionCost = (entityType, num) =>
  parseInt(
    pricingTable[entityType].price.plans[num]
      .replace(/\$/, '')
      .replace(/\/mo\./, ''),
    10,
  );
const entryCosts = (entityType) =>
  [0, 1, 2, 3].map((num) => parseEntryCost(entityType, num));
const subscriptionCosts = (entityType) =>
  [0, 1, 2, 3].map((num) => parseSubscriptionCost(entityType, num));
/**
 * returns best plan for a customer based on entry volume
 * @module
 * @param {string} entityType - INLT defined entity type (e.g. importer)
 * @param {number} entriesPerYear
 * @return {number}
 */
export default (entityType, entriesPerYear) => {
  const eCosts = entryCosts(entityType);
  const sCosts = subscriptionCosts(entityType);
  const plan0 = entriesPerYear * eCosts[0] + 12 * sCosts[0];
  const plan1 = entriesPerYear * eCosts[1] + 12 * sCosts[1];
  const plan2 = entriesPerYear * eCosts[2] + 12 * sCosts[2];
  const plan3 = entriesPerYear * eCosts[3] + 12 * sCosts[3];
  const min = Math.min(plan0, plan1, plan2, plan3);
  let bestPlan = -1;
  if (plan3 === min) bestPlan = 3;
  if (plan2 === min) bestPlan = 2;
  if (plan1 === min) bestPlan = 1;
  if (plan0 === min) bestPlan = 0;
  if (bestPlan === -1) bestPlan = 2;
  return bestPlan;
};
