/* eslint-disable camelcase */
import React, { Component } from 'react';
import './PublicPage.less';
import cms from 'libs/butter';
import Loading from 'components/Loading';
import Footer from 'components/Footer';

class PublicPage extends Component {
  state = {
    data: {},
    loaded: false,
  };

  prefixer = (field) => this.prefix + '_' + field;

  prefixed_fields = () => {
    const customFields = this.fields.map(this.prefixer);
    customFields.push(this.prefixer('title'), this.prefixer('subtitle'));
    return customFields;
  };

  componentDidMount = async () => {
    if (!this.state.loaded) {
      const response = await cms('content', 'retrieve', this.prefixed_fields());
      if (response) {
        const data = response.data.data;

        const simplifiedData = Object.keys(data).reduce(
          (returnObject, slug) => {
            const simplifiedSlug = slug.slice(this.prefix.length + 1);
            returnObject[simplifiedSlug] = data[slug];
            return returnObject;
          },
          {},
        );

        this.setState({
          loaded: true,
          data: simplifiedData,
        });
      }
    }
  };

  render() {
    const { loaded, data } = this.state;
    if (!loaded) return <Loading isLoading />;
    const prefix = this.prefix;
    const headersClasses = `content-header ${prefix}-headers`;
    const headerClass = `${prefix}-header`;
    const subheaderClass = `${prefix}-subheader`;
    let contentClasses = `content-main ${prefix}`;
    const publicCopyExceptions = ['bonds', 'importer_pricing', 'nvo_pricing'];
    if (!publicCopyExceptions.includes(prefix)) {
      contentClasses += ' public-copy';
    }
    const subheader = <h2 className={subheaderClass}>{data.subtitle}</h2>;

    return (
      <div className="content public-content">
        <div className={headersClasses}>
          <h1 className={headerClass}>{data.title}</h1>
          {subheader}
        </div>
        <div className={contentClasses}>{this.pageContent()}</div>
        <Footer />
      </div>
    );
  }
}

PublicPage.propTypes = {};

export default PublicPage;
