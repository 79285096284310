/* eslint-disable camelcase */
import React from 'react';
import RawHTML from 'components/RawHTML';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import './About.less';
import PublicPage from 'abstract/PublicPage';

const colLayout = {
  xs: 24,
  sm: 6,
  md: 6,
  lg: 6,
  xl: 6,
};

class About extends PublicPage {
  fields = ['copy', 'team_header', 'team_copy', 'team'];

  prefix = 'about';

  pageContent = () => {
    const { copy, team_header, team_copy, team } = this.state.data;
    const teamColumns = team.map((member, index) => (
      <Col key={index} {...colLayout}>
        <img src={member.photo} className="portrait" alt={member.name} />
        <p className="portrait-name">
          {member.name}
          <br />
          <span className="portrait-caption">{member.title}</span>
        </p>
      </Col>
    ));
    return (
      <div>
        <RawHTML html={copy} />
        <h2>{team_header}</h2>
        <RawHTML html={team_copy} />
        <Row gutter={16}>{teamColumns}</Row>
      </div>
    );
  };
}

export default About;
