import AWS from 'aws-sdk/global';
import { get } from 'lodash';
import * as Sentry from '@sentry/browser';
import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser,
} from 'amazon-cognito-identity-js';
// import {prefix} from './env';

const prefix = 'REACT_APP_';
// let loadingCredentials = null;
// let userEmail;

/**
 * inlt client side user API methods (AWS Cognito)
 */
export class Cognito {}
Cognito.makeUser = (inEmail) => {
  const email = inEmail ? inEmail.toLowerCase() : inEmail;
  // userEmail = email;
  return new CognitoUser({ Username: email, Pool: Cognito.getUserPool() });
};

Cognito.sendMFACode = (user, code, totp) =>
  new Promise((resolve, reject) => {
    const callbacks = {
      onSuccess: (result) => {
        resolve(result.getIdToken().getJwtToken());
      },
      onFailure: (err) => {
        reject(err);
      },
    };
    user.sendMFACode(code, callbacks, totp);
  });

Cognito.getUserData = ({ bypassCache = false }) =>
  new Promise(async (resolve, reject) => {
    const options = {};
    if (bypassCache === true) options.bypassCache = bypassCache;
    const user = Cognito.getCurrentUser();
    await Cognito.getSession(user);
    user.getUserData((err, data) => {
      if (err) reject(err);
      resolve(data);
    }, options);
  });

Cognito.getMFASettings = async (options = {}) => {
  const userData = await Cognito.getUserData(options);
  const PreferredMfaSetting = get(userData, 'PreferredMfaSetting', 'NOMFA');
  const UserMFASettingList = get(userData, 'UserMFASettingList', []);
  return {
    PreferredMfaSetting,
    UserMFASettingList,
  };
};

Cognito.listDevices = () =>
  new Promise(async (resolve, reject) => {
    const user = Cognito.getCurrentUser();
    await Cognito.getSession(user);
    user.listDevices(10, null, {
      onSuccess: (result) => resolve(result),
      onFailure: (err) => reject(err),
    });
  });

Cognito.setDeviceStatusRemembered = () =>
  new Promise(async (resolve, reject) => {
    const user = Cognito.getCurrentUser();
    await Cognito.getSession(user);
    await Cognito.getCachedDeviceKeyAndPassword(user);
    user.setDeviceStatusRemembered({
      onSuccess: (result) => {
        resolve(result);
      },
      onFailure: (err) => reject(err),
    });
  });

Cognito.setDeviceStatusNotRemembered = () =>
  new Promise(async (resolve, reject) => {
    const user = Cognito.getCurrentUser();
    await Cognito.getSession(user);
    await user.getCachedDeviceKeyAndPassword(user);
    user.setDeviceStatusNotRemembered({
      onSuccess: (result) => {
        resolve(result);
      },
      onFailure: (err) => reject(err),
    });
  });

Cognito.getCachedDeviceKeyAndPassword = async (user) => {
  await user.getCachedDeviceKeyAndPassword();
};

Cognito.setUserMfaPreference = ({ mfaType, disable = false }) =>
  new Promise(async (resolve, reject) => {
    const user = Cognito.getCurrentUser();
    await Cognito.getSession(user);
    const { phone_number: phoneNumber } = await Cognito.getUserAttributes();

    const { UserMFASettingList } = await Cognito.getMFASettings({
      bypassCache: true,
    });
    const smsPreferredMfa =
      (!disable && mfaType === 'SMS') ||
      (disable &&
        (mfaType === 'TOTP' && UserMFASettingList.includes('SMS_MFA')))
        ? true
        : false;
    const smsEnabled =
      (disable &&
        mfaType !== 'SMS' &&
        UserMFASettingList.includes('SMS_MFA')) ||
      (!disable &&
        (mfaType === 'SMS' || UserMFASettingList.includes('SMS_MFA')))
        ? true
        : false;
    const totpPreferredMfa =
      (!disable && mfaType === 'TOTP') ||
      (disable &&
        (mfaType === 'SMS' &&
          UserMFASettingList.includes('SOFTWARE_TOKEN_MFA')))
        ? true
        : false;
    const totpEnabled =
      (disable &&
        mfaType !== 'TOTP' &&
        UserMFASettingList.includes('SOFTWARE_TOKEN_MFA')) ||
      (!disable &&
        (mfaType === 'TOTP' ||
          UserMFASettingList.includes('SOFTWARE_TOKEN_MFA')))
        ? true
        : false;
    const smsMfaSettings = phoneNumber
      ? {
          PreferredMfa: smsPreferredMfa,
          Enabled: smsEnabled,
        }
      : null;
    const totpMfaSettings =
      mfaType === 'TOTP'
        ? {
            PreferredMfa: totpPreferredMfa,
            Enabled: totpEnabled,
          }
        : null;

    user.setUserMfaPreference(
      smsMfaSettings,
      totpMfaSettings,
      (err, result) => {
        if (err) reject(err);
        resolve(result);
      },
    );
  });

Cognito.disableMFA = () =>
  new Promise(async (resolve, reject) => {
    const user = Cognito.getCurrentUser();
    await Cognito.getSession(user);
    await user.setUserMfaPreference(
      { PreferredMfa: false, Enabled: false },
      { PreferredMfa: false, Enabled: false },
      (err, result) => {
        if (err) reject(err);
        resolve(result);
      },
    );
  });

Cognito.getAttributeVerificationCode = (attribute) =>
  new Promise(async (resolve, reject) => {
    const user = Cognito.getCurrentUser();
    await Cognito.getSession(user);
    await user.getAttributeVerificationCode(attribute, {
      onSuccess: () => {
        resolve({ result: 'SUCCESS' });
      },
      onFailure: (err) => reject(err),
    });
  });

Cognito.verifyAttribute = (attribute, code) =>
  new Promise(async (resolve, reject) => {
    const user = Cognito.getCurrentUser();
    await Cognito.getSession(user);
    await user.verifyAttribute(attribute, code, {
      onSuccess: (result) => resolve(result),
      onFailure: (err) => reject(err),
    });
  });

Cognito.generateURI = (entityId) =>
  new Promise(async (resolve, reject) => {
    const user = Cognito.getCurrentUser();
    await Cognito.getSession(user);
    const uri = await Cognito.associateSoftwareToken(user, entityId);
    resolve(uri);
  });

Cognito.associateSoftwareToken = (user, entityId) =>
  new Promise(async (resolve, reject) => {
    user.associateSoftwareToken({
      onFailure: (err) => {
        reject(err);
      },

      associateSecretCode: (secretCode) => {
        const uri = `otpauth://totp/INLT:${
          user.username
        }?secret=${secretCode}&issuer=${
          entityId === 'INLT' ? 'INLT' : window.location.hostname
        }`;
        resolve(uri);
      },
    });
  });

Cognito.verifySoftwareToken = (challengeAnswer) =>
  new Promise(async (resolve, reject) => {
    const user = Cognito.getCurrentUser();
    await Cognito.getSession(user);
    user.verifySoftwareToken(challengeAnswer, 'My TOTP device', {
      onSuccess: (result) => {
        resolve(result);
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });

Cognito.signInUser = (values) => {
  const email = values.email ? values.email.toLowerCase() : values.email;
  const authenticationData = {
    Username: email,
    Password: values.password,
  };

  const user = Cognito.makeUser(email);
  const authenticationDetails = new AuthenticationDetails(authenticationData);

  return new Promise(async (resolve, reject) => {
    const callbacks = {
      onSuccess: (result) => {
        resolve(result.getIdToken().getJwtToken());
      },

      onFailure: (err) => {
        reject(err);
      },

      mfaRequired: (type, codeDeliveryDetails) => {
        resolve({
          verification: true,
          user,
          delivery: {
            DeliveryMedium: codeDeliveryDetails.CODE_DELIVERY_DELIVERY_MEDIUM,
            Destination: codeDeliveryDetails.CODE_DELIVERY_DESTINATION,
          },
        });
      },

      newPasswordRequired: (userAttributes, requiredAttributes) => {
        resolve({ newPassword: true, user, userAttributes });
      },

      totpRequired: (type, deliveryDetails) => {
        resolve({
          verification: true,
          user,
          delivery: {
            DeliveryMedium: 'TOTP',
            DeviceName: deliveryDetails.FRIENDLY_DEVICE_NAME,
          },
        });
      },
    };

    user.authenticateUser(authenticationDetails, callbacks);
  });
};

Cognito.submitNewPassword = async (user, userAttributes, newPassword) => {
  // / this library (Which is official AWS but sketchy, doesnt accept this field back)
  delete userAttributes.email_verified;
  return new Promise((resolve, reject) => {
    user.completeNewPasswordChallenge(newPassword, userAttributes, {
      onSuccess: (result) => {
        resolve(result);
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};

Cognito.forgotPassword = (inEmail) => {
  const email = inEmail ? inEmail.toLowerCase() : inEmail;
  const user = Cognito.makeUser(email);

  return new Promise((resolve, reject) => {
    const callbacks = {
      onSuccess: (data) => {
        // console.log('CodeDeliveryData from forgotPassword: ' + data);
        resolve(data);
      },
      onFailure: (err) => {
        // console.log(err);
        reject(err);
      },
      // Optional automatic callback
      inputVerificationCode: (data) => {
        // console.log(data);
        resolve({
          verification: true,
          user,
          delivery: data.CodeDeliveryDetails,
        });
      },
    };

    user.forgotPassword(callbacks);
  });
};

Cognito.changePassword = async (oldPassword, newPassword) =>
  new Promise(async (resolve, reject) => {
    const user = Cognito.getCurrentUser();
    await Cognito.getSession(user);
    user.changePassword(oldPassword, newPassword, (err, result) => {
      if (err) reject(err);
      else resolve(result);
    });
  });

Cognito.confirmPassword = (user, verificationCode, newPassword) =>
  new Promise((resolve, reject) => {
    const callbacks = {
      onSuccess: (data) => {
        // console.log(data);
        resolve(data);
      },
      onFailure: (err) => {
        // console.log(err);
        reject(err);
      },
    };
    user.confirmPassword(verificationCode, newPassword, callbacks);
  });

Cognito.signOutUser = () => {
  const currentUser = Cognito.getCurrentUser();
  if (currentUser !== null) {
    currentUser.signOut();
  }
  if (AWS.config.credentials) {
    AWS.config.credentials.clearCachedId();
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({});
  }
};

Cognito.getUserPool = () => {
  const UserPoolId = process.env[prefix + 'COGNITO_USER_POOL_ID'];
  const ClientId = process.env[prefix + 'COGNITO_APP_CLIENT_ID'];
  return new CognitoUserPool({
    UserPoolId,
    ClientId,
    AdvancedSecurityDataCollectionFlag: true,
  });
};

Cognito.getCurrentUser = () => {
  return Cognito.getUserPool().getCurrentUser();
};

Cognito.getSession = (user) => {
  return new Promise((resolve, reject) => {
    user.getSession((err, session) => {
      if (err) reject(err);
      resolve(session);
    });
  });
};

Cognito.getUserToken = (currentUser) => {
  return new Promise(async (resolve, reject) => {
    const session = await Cognito.getSession(currentUser);
    resolve(session.getIdToken().getJwtToken());
  });
};

Cognito.getUserAttributes = () => {
  return new Promise(async (resolve, reject) => {
    const user = Cognito.getCurrentUser();
    await Cognito.getSession(user);
    user.getUserAttributes((error, result) => {
      if (error) {
        reject(error);
        return;
      }
      const obj = {};
      result.forEach((attr) => {
        obj[attr.Name] = attr.Value;
      });
      resolve(obj);
    });
  });
};

Cognito.updateAttributes = (attributes) => {
  return new Promise(async (resolve, reject) => {
    const user = Cognito.getCurrentUser();
    await Cognito.getSession(user);
    user.updateAttributes(attributes, (err, result) => {
      if (err) reject(err);
      resolve(result);
    });
  });
};

Cognito.getAwsCredentials = async (userToken) => {
  // if (loadingCredentials) return loadingCredentials;

  if (
    AWS.config.credentials &&
    Date.now() < AWS.config.credentials.expireTime - 60000
  ) {
    return;
  }

  const UserPoolId = process.env[prefix + 'COGNITO_USER_POOL_ID'];
  const CognitoRegion = process.env[prefix + 'COGNITO_REGION'];
  const IdentityPoolId = process.env[prefix + 'IDENTITY_POOL_ID'];

  const authenticator = `cognito-idp.${CognitoRegion}.amazonaws.com/${UserPoolId}`;

  const params = {
    IdentityPoolId,
    Logins: {
      [authenticator]: userToken,
    },
  };
  AWS.config.region = CognitoRegion;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials(params);

  await new Promise((resolve, reject) => {
    AWS.config.credentials.refresh((error) => {
      if (error) reject(error);
      else resolve();
    });
  });

  // dont send this to sentry anymore, going over usage limits
  // Sentry.captureMessage('UserAuth', {extra: {email: userEmail, credParams: params}, level: 'info'});

  // workaround when this function is called twice
  // loadingCredentials = AWS.config.credentials.getPromise();
  // await loadingCredentials;

  // loadingCredentials = null;
};

Cognito.getUserId = () => {
  if (AWS.config.credentials) {
    return AWS.config.credentials.identityId;
  } else {
    return undefined;
  }
};

Cognito.authUser = async (options) => {
  if (
    AWS.config.credentials &&
    AWS.config.credentials.accessKeyId &&
    AWS.config.credentials.secretAccessKey &&
    Date.now() < AWS.config.credentials.expireTime - 60000 &&
    (!options || !options.force)
  ) {
    return AWS.config.credentials;
  }

  const currentUser = Cognito.getCurrentUser();

  if (currentUser === null) {
    return false;
  }

  const userToken = await Cognito.getUserToken(currentUser);

  await Cognito.getAwsCredentials(userToken);

  return AWS.config.credentials;
};

export const logout = () => {
  Cognito.signOutUser();
  Sentry.setUser();
};
