import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import initServices from './services';
import registerServiceWorker from 'libs/registerServiceWorker';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import ScrollToTop from './scroll';
import { Provider } from 'react-redux';
import createStore from './store';
import './index.less';
import { polyfillLoader } from 'polyfill-io-feature-detection';

if (!Object.values || !Element.prototype.prepend) {
  polyfillLoader({
    features:
      'fetch,Set,Object.assign,String.prototype.includes,Array.prototype.includes,Object.values,String.prototype.startsWith,String.prototype.endsWith,Array.prototype.find,String.fromCodePoint',
    onCompleted: main,
  });
} else {
  main();
}

/**
 * start react
 * @return {undefined}
 */
function main() {
  const store = createStore({});
  const persistor = persistStore(store);
  initServices();
  render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <ScrollToTop>
            <App />
          </ScrollToTop>
        </Router>
      </PersistGate>
    </Provider>,
    document.getElementById('root'),
  );
  registerServiceWorker();
}
