export default {
  importer: {
    plan: 2,
    entries: 200,
    realEntries: 200,
    percentSelf: 0,
    entryFee: 90,
    isfFee: 25,
    pgaFee: 25,
    lineFee: 10,
    otherFee: 5,
  },
  forwarder: {
    plan: 3,
    entries: 350,
    realEntries: 350,
    percentSelf: 0,
    entryFee: 65,
    isfFee: 2,
    pgaFee: 5,
    lineFee: 5,
    otherFee: 5,
  },
};
