import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Tooltip, Icon, Spin, Button } from 'antd';
import { entityTypes } from 'libs/utils';
import cms from 'libs/butter';
import MediaQuery from 'react-responsive';
import gaEvent from 'libs/gaEvent';
import './PricingGrid.less';

const planNames = ['Lite', 'Basic', 'Pro', 'Enterprise'];
const keys = ['users', 'storage', 'bond', 'pro', 'annual'];

class PricingGrid extends Component {
  state = {
    pricingTable: {},
    loaded: false,
  };

  row = (key) => {
    const options = this.state.pricingTable[key];
    const check = <Icon type="check" />;
    const uncheck = <Icon className="pricing-uncheck" type="minus" />;
    let tooltip = (
      <td key={0}>
        <Tooltip
          className="pricing-tooltip"
          placement="bottom"
          title={options.tooltip}
          trigger="hover"
        >
          <span className="pricing-tooltip-text">{options.name}</span>
        </Tooltip>
      </td>
    );
    if (options.tooltip === '')
      tooltip = (
        <td key={0}>
          <span>{options.name}</span>
        </td>
      );
    let columns;
    if (options.plans.length !== 4) {
      throw Error(`${key} has wrong pricing table data`);
    } else {
      columns = options.plans.map((col, index) => {
        if (this.props.entityType === entityTypes.forwarder && index === 0) {
          return null;
        }
        return (
          <td
            onClick={() => this.changePlan(index)}
            className={
              this.props.form.getFieldValue('plan') === index
                ? 'activated pricing-element'
                : 'pricing-element'
            }
            key={index}
          >
            {col.toString() === 'true'
              ? check
              : col.toString() === 'false'
              ? uncheck
              : col}
          </td>
        );
      });
    }
    return (
      <tr key={key}>
        {tooltip}
        {columns}
      </tr>
    );
  };

  mobileCards = (planIndex) => {
    if (this.props.entityType === entityTypes.forwarder && planIndex === 0)
      return null;
    return (
      <Card className="plan">
        <h2 className="plan-name">{planNames[planIndex]}</h2>
        <h3>{this.state.pricingTable.price.plans[planIndex]}</h3>
        <h3>{`${
          this.state.pricingTable.full.plans[planIndex]
        } full-service entries`}</h3>
        <ul className="features">
          {keys.reduce((acc, key) => {
            const item = this.state.pricingTable[key];
            if (item) {
              acc.push(
                <li key={key}>{`${item.name}: ${item.plans[planIndex]}`}</li>,
              );
            }
            return acc;
          }, [])}
        </ul>
      </Card>
    );
  };

  changePlan = (plan) => {
    const { form, changePlan } = this.props;
    gaEvent('Pricing', 'Table Column Clicked', planNames[plan]);
    form.setFieldsValue({ plan });
    if (changePlan) changePlan(plan);
  };

  componentDidMount = async () => {
    if (!this.state.loaded) {
      const key = `${this.props.entityType}_pricing`;
      const response = await cms('content', 'retrieve', [key]);
      if (response) {
        const data = response.data.data;
        const pricingTable = data[key].reduce((acc, cur) => {
          acc[cur.key] = {
            name: cur.name,
            tooltip: cur.tooltip,
            plans: [cur.lite, cur.basic, cur.pro, cur.enterprise],
          };
          return acc;
        }, {});
        this.setState({ pricingTable, loaded: true });
      }
    }
  };

  render() {
    const { entityType, form, initialValue } = this.props;
    form.getFieldProps('plan', { initialValue });
    const plan = form.getFieldValue('plan');
    if (!this.state.loaded) return <Spin />;
    const rows = Object.keys(this.state.pricingTable).map((name) =>
      this.row(name),
    );
    return (
      <MediaQuery minDeviceWidth={768}>
        {(matches) => {
          if (matches) {
            return (
              <div className="pricing-grid">
                <table>
                  <thead>
                    <tr>
                      <th />
                      {entityType === entityTypes.importer && (
                        <th>
                          <Button
                            className={
                              plan === 0
                                ? 'activated plan-button'
                                : 'plan-button'
                            }
                            onClick={() => this.changePlan(0)}
                          >
                            Lite
                          </Button>
                        </th>
                      )}
                      <th>
                        <Button
                          className={
                            plan === 1 ? 'activated plan-button' : 'plan-button'
                          }
                          onClick={() => this.changePlan(1)}
                        >
                          Basic
                        </Button>
                      </th>
                      <th>
                        <Button
                          className={
                            plan === 2 ? 'activated plan-button' : 'plan-button'
                          }
                          onClick={() => this.changePlan(2)}
                        >
                          Pro
                        </Button>
                      </th>
                      <th>
                        <Button
                          className={
                            plan === 3 ? 'activated plan-button' : 'plan-button'
                          }
                          onClick={() => this.changePlan(3)}
                        >
                          Enterprise
                        </Button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{rows}</tbody>
                </table>
                <p className="center space-top space-bottom">
                  * Subscription fee prices shown are paid annually. A 20%
                  increase applies if paying monthly.
                </p>
              </div>
            );
          } else {
            return (
              <div className="mobile">{[0, 1, 2, 3].map(this.mobileCards)}</div>
            );
          }
        }}
      </MediaQuery>
    );
  }
}

PricingGrid.propTypes = {
  entityType: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  initialValue: PropTypes.number.isRequired,
};

export default PricingGrid;
