import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AppHeader from './component';

const mapStateToProps = ({ isFetching, signup }) => ({
  isFetching,
  newsletterVisible: signup.newsletterVisible,
  signupVisible: signup.signupVisible,
});

export default withRouter(connect(mapStateToProps)(AppHeader));
