import moment from 'moment';
import { get, set } from 'lodash';
import getFullFormKeys from 'libs/getFullFormKeys';
import isISO from 'libs/isISO';

// These are keys which we want to display the same regardless of where you are geographically.
// for example, for in-bonds, the time of closing is entered and displayed at the time that the event actually happened, unadjusted for time zones.
const localTimeKeys = [
  'permissionLetterDate',
  'previousDate',
  'actualDate',
  'importDate',
  'exportDate',
  'estimatedEntryDate',
  'inBondDate',
  'lfd',
  'printDate',
];
export const momentize = (initialValues) => {
  if (!initialValues) return initialValues;

  const fullFormKeys = getFullFormKeys({
    values: initialValues,
  });

  fullFormKeys.forEach((key) => {
    const value = get(initialValues, key);
    if (
      (isISO(value) ||
        key.endsWith('Date') ||
        key.endsWith('Time') ||
        key === 'lfd') &&
      !moment.isMoment(value)
    ) {
      set(initialValues, key, moment(value));
    }
  });
  return initialValues;
};

export const unMomentize = (object, parentKey) => {
  if (!object) return object;

  // primitives
  if (object && typeof object !== 'object') return object;

  // arrays
  if (Array.isArray(object)) return object.map(unMomentize);

  // moment objects
  if (moment.isMoment(object)) {
    let timeString;
    if (
      parentKey &&
      object.toISOString &&
      localTimeKeys.some((localKey) => parentKey.toString().endsWith(localKey))
    ) {
      timeString = object.toISOString(true); // boolean arg keeps it local
      timeString = timeString.slice(0, 23);
    } else if (object.toISOString) timeString = object.toISOString();
    return timeString;
  }

  // objects
  return Object.entries(object).reduce((acc, [key, value]) => {
    acc[key] = unMomentize(value, key);
    return acc;
  }, {});
};
