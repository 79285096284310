export const err = (inMsg) => {
  let msg = inMsg;
  if (!msg) {
    msg = 'Sorry, an error occured. Please try again later.';
  }
  throw new Error(msg);
};

export const logErr = async (fun) => {
  try {
    return await fun;
  } catch (e) {
    console.log(e.message); // eslint-disable-line no-console
    throw e;
  }
};

export const stages = {
  dev: 'dev',
  test: 'test',
  qa: 'qa',
  prod: 'prod',
};

export const objectIdName = (objectType) =>
  objectType && `${objectType.slice(0, -1)}Id`;

export const splitShipmentId = (longShipmentId) => ({
  importerId: longShipmentId.substr(0, 7),
  shipmentId: longShipmentId.substr(7),
});
export const joinShipmentId = (shipment) => {
  if (!shipment) return shipment;
  const { importerId, shipmentId } = shipment;
  return `${importerId}${shipmentId}`;
};
export const shortId = (shipmentId) =>
  shipmentId && shipmentId.startsWith('I') ? shipmentId.substr(7) : shipmentId;

export const splitInvoiceId = (invoiceId) => ({
  entityId: invoiceId.substr(0, 7),
  invoiceId: parseInt(invoiceId.substr(8), 10),
});
export const joinInvoiceId = (invoice) => {
  if (!invoice) return invoice;
  const { entityId, invoiceId } = invoice;
  return `${entityId}V${invoiceId.toString()}`;
};
export const joinDocId = (doc) => {
  if (!doc) return doc;
  const { folder, docId } = doc;
  return `${folder}/${docId}`;
};
export const splitDocKey = (docKey) => {
  const split = docKey.split('/');
  const docId = split[split.length - 1];
  const folder = split.slice(0, -1).join('/');
  return {
    folder,
    docId,
  };
};

/**
 * generates S3 doc folder name (also used as primary index on docs table)
 * @param {Object} ids
 * @param {string} objectType
 * @param {boolean} temp
 * @return {string}
 */
export const getDocFolder = (ids, objectType, temp) => {
  let folder;
  if (objectType === objectTypes.invoices) {
    folder = `${objectType}/${ids.entityId}/${ids.invoiceId}`;
  } else if (objectType === objectTypes.importers) {
    folder = `${objectType}/${ids.importerId}`;
  } else if (objectType === objectTypes.forwarders) {
    folder = `${objectType}/${ids.forwarderId}`;
  } else {
    // these can be temporary files uploaded if the classification or product record isnt created yet
    folder = `${objectType}/${temp ? 'temp' : ids.importerId}/${
      ids[objectIdName(objectType)]
    }`;
  }
  return folder;
};

export const dedupIndexes = {
  forwarders: ['fmcNumber', 'name', 'scac', 'address1'],
  destinations: ['firmsCode', 'duns', 'ein', 'name'],
  importers: ['iorNumber', 'ein', 'name'],
  suppliers: ['duns', 'MID', 'name'],
  truckers: ['duns', 'ein', 'scac', 'name'],
};

export const plans = {
  lite: 0,
  basic: 1,
  pro: 2,
  enterprise: 3,
};

const commonPrivate = [
  'contactName',
  'contactEmail',
  'contactPhone',
  'contactPhoneCountry',
  'notes',
];

export const privateFields = {
  truckers: [...commonPrivate],
  destinations: [...commonPrivate],
  suppliers: [...commonPrivate, 'incoterm', 'netsuiteId'],
  forwarders: [...commonPrivate],
  importers: [...commonPrivate],
};

export const customerObjectTypes = ['forwarders', 'importers'];

// objects are plural

export const objectTypes = {
  users: 'users',
  shipments: 'shipments',
  products: 'products',
  classifications: 'classifications',
  truckers: 'truckers',
  destinations: 'destinations',
  suppliers: 'suppliers',
  forwarders: 'forwarders',
  importers: 'importers',
  consignees: 'consignees',
  invoices: 'invoices',
  templates: 'templates',
};

export const globalObjectTypes = [
  objectTypes.truckers,
  objectTypes.destinations,
  objectTypes.suppliers,
  objectTypes.forwarders,
  objectTypes.importers,
  objectTypes.consignees,
];

const objectIdTypeMap = {
  P: objectTypes.products,
  S: objectTypes.suppliers,
  D: objectTypes.destinations,
  T: objectTypes.truckers,
  F: objectTypes.forwarders,
  I: objectTypes.importers,
  V: objectTypes.invoices,
  X: objectTypes.templates,
  C: objectTypes.consignees,
  H: 'hts',
};

/**
 * Gets the objectType of an objectId
 * @param {String} objectId
 * @return {String}
 */
export const getObjectType = (objectId) => {
  if (!objectId) return objectId;
  const stringified = objectId.toString();
  const firstChar = stringified.charAt(0);
  if (firstChar === 'I' && stringified.length === 14) {
    return objectTypes.shipments;
  } else if (stringified.charAt(7) === 'V') {
    return objectTypes.invoices;
  } else if (objectId.startsWith('us-west-2')) {
    return objectTypes.users;
  } else if (objectId.startsWith('C') && objectId.length !== 7) {
    return objectTypes.classifications;
  } else {
    const type = objectIdTypeMap[firstChar];
    if (!type) {
      return objectTypes.users;
    } else {
      return type;
    }
  }
};

const entityIdObjectTypeMap = {
  T: 'teams',
  F: objectTypes.forwarders,
  I: objectTypes.importers,
};
export const getEntityObjectType = (objectId) =>
  objectId && entityIdObjectTypeMap[objectId.charAt(0)];

export const dependentObjectTypes = {
  shipments: [
    'products',
    'suppliers',
    'destinations',
    'forwarders',
    'truckers',
    'importers',
    'consignees',
  ],
  products: ['importers', 'suppliers'],
  suppliers: ['importers'],
  truckers: ['importers'],
  forwarders: ['importers'],
  destinations: ['importers'],
  invoices: ['importers', 'shipments', 'classifications'],
};

// entities are singular, these are ppl with users and access to system, they can also be objects

export const entityTypes = {
  importer: 'importer',
  forwarder: 'forwarder',
  team: 'team',
};

const entityIdTypeMap = {
  T: entityTypes.team,
  I: entityTypes.importer,
  F: entityTypes.forwarder,
};

export const getEntityType = (entityId) =>
  entityId && entityIdTypeMap[entityId.charAt(0)];
