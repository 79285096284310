import React from 'react';
import PropTypes from 'prop-types';
import './SignupModal.less';
import { Modal } from 'antd';
import SignupForm from 'components/SignupForm';

const SignupModal = ({ visible, onSubmit, onCancel, type }) => {
  let className = 'public-modal';
  let maskStyle;
  if (type === 'newsletter') {
    className += ' newsletter-modal';
    maskStyle = { backgroundColor: 'rgba(0,0,0,0.2)' };
  } else {
    className += ' signup-modal';
    maskStyle = { backgroundColor: '#643D52ee' };
  }

  return (
    <Modal
      visible={visible}
      footer={null}
      wrapClassName={className}
      maskStyle={maskStyle}
      onCancel={onCancel}
      style={{ backgroundColor: 'transparent', boxShadow: 'none' }}
      closable={false}
    >
      {type === 'newsletter' && (
        <h1 className="newsletter-title">
          Sign up to receive updates on tariffs, importing trends, and more!
        </h1>
      )}
      <SignupForm onSubmit={onSubmit} type={type} />
    </Modal>
  );
};

SignupModal.propTypes = {
  visible: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  type: PropTypes.string,
};

export default SignupModal;
