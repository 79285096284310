import { message } from 'antd';
import GA from 'react-ga';
import sentryCatch from 'libs/sentryCatch';

const cache = {};

export default async (path, subpath, args) => {
  try {
    let response;
    if (path === 'content') {
      const cacheKey = args.join();
      if (!cache[cacheKey]) {
        cache[cacheKey] = window.butter[path][subpath](args);
      }
      response = await cache[cacheKey];
    } else {
      response = window.butter[path][subpath](args);
    }
    return response;
  } catch (e) {
    if (process.env.REACT_APP_STAGE === 'prod') {
      GA.exception({
        description: e.message,
        fatal: true,
      });
    }
    sentryCatch(e);
    message.error(
      `Sorry, we weren't able to fetch your content, please try again.`,
    );
    return null;
  }
};
