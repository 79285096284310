export const ein = /^\d{2}-\d{7}[A-Z0-9]{2}$/;
export const einShort = /^\d{2}-\d{7}$/;
export const cbp = /^\d{6}-\d{5}$/;
export const ssn = /^\d{3}-\d{2}-\d{4}$/;
export const airWaybill = /^\d{3}-\d{8}$/;
export const scac = /^[A-Z]{4}$/;
export const email = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
export const duns = /^\d{9}$/;
export const payerUnitNumber = /^\d{6}$/;
export const htsNumber = /^\d{4}\.?\d{2}\.?\d{2,4}$/;
export const hsNumber = /^\d{4}\.?\d{2}\.?(\d{2,4})?$/;
export const caseNumber = /^[AC]-{0,1}\d{3}-{0,1}\d{3}-{0,1}\d{3}$/;
export const shipmentDocLetters = /[a-z]{4}[a-z0-9]+/g;
export const shipmentDocNumbers = /[0-9]{4}[a-z0-9]+/g;
export const url = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
export default {
  ein,
  einShort,
  cbp,
  ssn,
  airWaybill,
  scac,
  email,
  duns,
  payerUnitNumber,
  htsNumber,
  caseNumber,
  shipmentDocLetters,
  shipmentDocNumbers,
  url,
};
