import React from 'react';
import PropTypes from 'prop-types';
import './SignupModalButton.less';
import { Button } from 'antd';

const SignupModalButton = ({ buttonClassName, type, children, openModal }) => (
  <div className="signup-button-wrapper">
    <Button type={type} className={buttonClassName} onClick={openModal}>
      {children}
    </Button>
  </div>
);

SignupModalButton.propTypes = {
  buttonClassName: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default SignupModalButton;
