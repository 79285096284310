import { message } from 'antd';
import GA from 'react-ga';
import { get } from 'lodash';

export default (e, override) => {
  let network = e.message.indexOf('NetworkError') !== -1;
  network = network || e.message.indexOf('Network Error') !== -1;
  const notFound = e.message.indexOf('NotFound') !== -1;
  let description = 'No description';
  if (network || notFound || override) {
    message.error(
      'Oops! There seems to be a network issue, please check your connection and try again',
      6.0,
    );
    description = e.message;
  } else {
    let messageText = e.message || get(e, 'response.data.message');
    if (messageText) {
      if (messageText.startsWith('GraphQL error: ')) {
        messageText = messageText.slice(15);
      }
      if (
        !messageText.includes('arn:aws:sts') &&
        !messageText.toLowerCase().includes('internal server error')
      ) {
        message.error(messageText, 5.0);
      }
      description = messageText;
    } else {
      description = e.toString();
    }
  }
  if (process.env.REACT_APP_STAGE === 'prod') {
    GA.exception({
      description,
      fatal: true,
    });
  }
  return network;
};
