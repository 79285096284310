import React from 'react';
import { remove, add } from 'libs/flushAll';
import { get, debounce } from 'lodash';

export default ({ triggerMs = 1000, getValueFromEvent } = {}) => {
  return (C) => {
    class DebouncedComponent extends React.Component {
      state = {};

      onChange = debounce((value) => {
        try {
          this.props.onChange(value);
        } catch (e) {
          // form is probably no longer in existence
        }
        this.controlled = false;
      }, triggerMs);

      constructor(props) {
        super(props);
        this.uuid = add(this.onChange);
      }

      componentWillUnmount = () => {
        if (this.onChange && typeof this.onChange.flush === 'function')
          this.onChange.flush();
        remove(this.uuid);
      };

      shouldComponentUpdate = (nextProps, nextState) => {
        // TODO limit based on other stuff
        return true;
      };

      UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (
          !this.controlled &&
          // nextProps.value && // having this check prevented us from triggering a re-render when clearing a field
          this.state.value &&
          nextProps.value !== this.state.value
        ) {
          this.setState({ value: nextProps.value });
        }
      };

      handleOnChange = (e) => {
        const value =
          typeof e === 'number'
            ? e
            : getValueFromEvent
            ? getValueFromEvent(e)
            : get(e, 'target.value');
        this.setState({ value });
        this.controlled = true;
        this.onChange(value);
      };

      render() {
        const obj = { ...this.props, ...this.state };
        return <C {...obj} onChange={this.handleOnChange} />;
      }
    }
    return DebouncedComponent;
  };
};
