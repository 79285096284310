import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { PropTypes } from 'prop-types';
import './App.less';
import Routes from 'routes/Routes';
import ErrorBoundary from 'components/ErrorBoundary';
import Header from 'components/PublicHeader';
import GA from 'react-ga';
import { withRouter } from 'react-router-dom';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { NEWSLETTER_SHOW } from 'actions';
import Helmet from 'react-helmet';
import timeout from 'libs/timeout';
import gaEvent from 'libs/gaEvent';

const { Content } = Layout;

let allowOpenModal = false;

// eslint-disable-next-line require-jsdoc
class App extends Component {
  componentDidMount = async () => {
    if (this.props.location.pathname === '/index.html') {
      this.props.history.push('/');
    }
    this.unlisten = this.props.history.listen((location, action) => {
      if (process.env.REACT_APP_STAGE === 'prod')
        GA.pageview(location.pathname);
    });
    await timeout(3000);
    this.contentDOMNode = ReactDOM.findDOMNode(this.content);
    this.contentDOMNode.addEventListener('scroll', this.handleScroll);
    allowOpenModal = true;
  };

  handleScroll = (e) => {
    if (this.contentDOMNode.scrollTop === this.contentDOMNode.scrollTopMax) {
      this.openModal();
      gaEvent('Scroll', 'Reached Bottom');
    }
  };

  helmet = () => {
    let title;
    if (this.props.location.pathname.match(/^\/blog\/posts\/.+$/)) {
      return null;
    } else if (this.props.location.pathname.match(/^\/blog/)) {
      title = 'INLT | Blog';
    } else {
      title = 'INLT | Simple cloud-based solutions for importing';
    }

    return (
      <Helmet>
        <title>{title}</title>
      </Helmet>
    );
  };

  openModal = () => {
    if (allowOpenModal) {
      this.props.dispatch(NEWSLETTER_SHOW(true));
    }
  };

  // eslint-disable-next-line require-jsdoc
  render() {
    return (
      <Layout className="root-layout">
        {this.helmet()}
        <Header />
        <Layout>
          <Content
            ref={(content) => (this.content = content)}
            className={'root-layout-content'}
          >
            <ErrorBoundary>
              <Routes location={this.props.location} />
            </ErrorBoundary>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(connect()(App));
