import React, { Component } from 'react';
import { Button, Radio, Form, Input, message } from 'antd';
import { email as emailRegex } from 'libs/regex';
import { SIGNUP, SIGNUP_CAPTURE } from 'actions';
import { connect } from 'react-redux';
import './SignupForm.less';

class SignupForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  handleSignup = async (evt) => {
    evt.preventDefault();

    this.setState({ isLoading: true });

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        if (this.props.type === 'newsletter') {
          values.type = 'newsletter';
        } else {
          values.type = 'forwarder'; // biz wants us to always direct to forwarder path
        }
        console.log('Form values', values);
        await this.props.dispatch(SIGNUP(values));
        this.props.form.setFieldsValue({ email: undefined });
        this.setState({ isLoading: false });
        this.props.dispatch(SIGNUP_CAPTURE());
        if (this.props.onSubmit) this.props.onSubmit();
      } else {
        if (err.type) {
          message.error(
            'Please select what type of account you are signing up for',
          );
        }
        if (err.email) {
          message.error('Please enter a valid email address');
        }
        this.setState({ isLoading: false });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form
        onSubmit={this.handleSignup}
        className={`signup-form ${this.props.type}`}
      >
        <div className="form-inline">
          <h3 className="signup-form-email-label">Email</h3>
          <Form.Item className="email-input-form-item">
            {getFieldDecorator('email', {
              rules: [{ required: true, message: ' ', pattern: emailRegex }],
            })(<Input className="email-input" size="large" />)}
          </Form.Item>
        </div>
        {/* {this.props.type !== 'newsletter' && (
          <div className="form-inline importer-or-forwarder">
            <Form.Item>
              {getFieldDecorator('type', {
                initialValue: 'importer',
                rules: [{ required: true, message: ' ' }],
              })(
                <Radio.Group size="large" className="inlt-radio">
                  <Radio.Button value="importer">I am an importer</Radio.Button>
                  <Radio.Button value="forwarder">
                    I am a freight forwarder
                  </Radio.Button>
                </Radio.Group>,
              )}
            </Form.Item>
          </div>
        )} */}
        <div className="form-inline signup-submit-div">
          <Form.Item>
            <Button
              loading={this.state.isLoading}
              type="secondary"
              htmlType="submit"
              size="large"
              className="signup-form-submit"
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    );
  }
}

export default connect((state) => ({}))(Form.create()(SignupForm));
