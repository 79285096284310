import React from 'react';
import debounceComponent from 'components/debounceComponent';
import PropTypes from 'prop-types';
import { Form, InputNumber } from 'antd';
import LabelAbove from 'components/LabelAbove';
import './CurrencyField.less';

const DebouncedCurrencyField = debounceComponent()(InputNumber);

const CurrencyField = ({
  size,
  formItemLayout,
  max,
  initialValue,
  form,
  label,
  field,
  disabled,
  readOnly,
  onChange,
  precision,
  step,
  labelAbove,
  required,
  symbol,
  debounce = false,
  inputClassName,
}) => {
  // input number needs an input mask like text-mask-core, but that doesnt work with it since it has its own formatter and parser methods, so we roll our own
  formItemLayout = formItemLayout || {};
  const C = debounce ? DebouncedCurrencyField : InputNumber;
  let className = 'currency-field';
  if (size) className += ` has-size ${size}`;
  if (readOnly) inputClassName += ' read-only';
  return (
    <Form.Item
      label={label}
      {...formItemLayout}
      className={className}
      help={null}
    >
      {labelAbove && <LabelAbove text={labelAbove} />}
      {form.getFieldDecorator(field, {
        initialValue,
        rules: [{ required, message: ' ' }],
      })(
        <C
          disabled={disabled}
          className={inputClassName}
          size="large"
          onChange={onChange}
          formatter={(value) => {
            const v = value.toString();
            const split = v.split('.');
            let str = `${symbol} ${split[0].replace(
              /(\d)(?=(\d\d\d)+(?!\d))/g,
              '$1,',
            )}`;
            if (split.length > 1) {
              str += '.' + split[1]; // .toString().replace(/0+$/g, '');
            }
            return str;
          }}
          parser={(value) =>
            value &&
            value
              .replace(symbol, '')
              .replace(/,/g, '')
              .replace(/\s?|(,*)/g, '')
          }
          min={0}
          precision={precision}
          max={max}
          step={step}
        />,
      )}
    </Form.Item>
  );
};

CurrencyField.propTypes = {
  form: PropTypes.any.isRequired,
  label: PropTypes.any,
  field: PropTypes.string.isRequired,
  max: PropTypes.number.isRequired,
  formItemLayout: PropTypes.object,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  step: PropTypes.number,
  initialValue: PropTypes.number,
  size: PropTypes.string,
  labelAbove: PropTypes.any,
};

CurrencyField.defaultProps = {
  step: 1,
  formItemLayout: {},
  symbol: '$',
  max: 100000000,
  precision: 2,
  required: false,
  inputClassName: '',
};

export default CurrencyField;
