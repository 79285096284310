export default {
  importer: {
    price: {
      name: 'Membership Price*',
      tooltip: '',
      plans: ['$19/mo.', '$99/mo.', '$249/mo.', '$499/mo.'],
    },
    full: {
      name: 'Price per traditional full-service entry',
      tooltip: '', // 'Your current rates might look something like this:',
      plans: ['$80', '$60', '$50', '$40'],
    },
    self: {
      name: 'Price per self-serve entry',
      tooltip:
        "You don't have current rates for this service because nobody else offers it.",
      plans: ['$30', '$20', '$15', '$10'],
    },
    isf: {
      name: 'Cost per ISF',
      tooltip:
        'Most brokers and fowarders charge between $15 and $35 to files the ISF, we charge nothing.',
      plans: ['Free'],
    },
    invoices: {
      name: 'Additional invoices and lines',
      tooltip:
        'Most brokers will offer 2 or 3 lines for free and charge a line fee of $3 to $5 for each line thereafter, we charge nothing.',
      plans: ['Free'],
    },
    users: {
      name: 'No. of free users',
      tooltip: '$4.95 per month for each additional user.',
      plans: ['5', '10', '20', '50'],
    },
    storage: {
      name: 'Document storage term',
      tooltip:
        "The cost of storage has never been cheaper and we're happy to retain documents for (nearly) the life of our partnership.",
      plans: ['5 years', '10 years', '15 years', '20 years'],
    },
    bond: {
      name: 'Continuous bond discount',
      tooltip: 'Discount taken from our normal rate of $249 per year.',
      plans: ['0%', '15%', '30%', 'Free'],
    },
    pro: {
      name: 'Pro services discount',
      tooltip: 'Partner rate at $225 per hour.',
      plans: ['0%', '10%', '15%', '20%'],
    },
    annual: {
      name: 'Annual free product classification',
      tooltip:
        '$5 per additional classification (minus pro services discount).',
      plans: ['0', '100', '250', '500'],
    },
    track: {
      name: 'Real-time track and trace',
      tooltip:
        'Not your run of the mill track and trace - you will get import date, entry date, release date, date delivery order was issued, and last free day',
      plans: [true],
    },
    exam: {
      name: 'Exam resolution',
      tooltip:
        'You will never be expected to contact customs directly, we resolve all holds and exams on your behalf.',
      plans: [true],
    },
    pga: {
      name: 'Partner Government Agency filing',
      tooltip:
        'Make declarations to all other government agencies that regulate the import of your product(s).',
      plans: [true],
    },
    wizard: {
      name: 'Product upload wizard',
      tooltip:
        'You already have a product database? Our upload wizard allows you to import your product library for ease-of-use and compliance.',
      plans: [true],
    },
    edu: {
      name: 'Full educational webinar access',
      tooltip:
        'INLT was born out of a trade consulting firm and training is our sweet spot, you can expect monthly training webinars at no cost.',
      plans: [true],
    },
  },
  forwarder: {
    price: {
      name: 'Membership Price*',
      tooltip: '',
      plans: ['$19/mo.', '$99/mo.', '$249/mo.', '$499/mo.'],
    },
    full: {
      name: 'Price per traditional full-service entry',
      tooltip: '', // 'Your current rates might look something like this:',
      plans: ['$80', '$50', '$40', '$30'],
    },
    isf: {
      name: 'Cost per ISF',
      tooltip:
        'Most brokers and fowarders charge between $15 and $35 to files the ISF, we charge nothing.',
      plans: ['Free'],
    },
    singleEntryBond: {
      name: 'Single entry bond rate',
      tooltip: '$45 minimum',
      plans: ['$4.50/$1000'],
    },
    isfBond: {
      name: 'ISF bond rate',
      tooltip: '',
      plans: ['$45'],
    },
    invoices: {
      name: 'Additional invoices and lines',
      tooltip:
        'Most brokers will offer 2 or 3 lines for free and charge a line fee of $3 to $5 for each line thereafter, we charge nothing.',
      plans: ['Free'],
    },
    users: {
      name: 'No. of free users',
      tooltip: '$4.95 per month for each additional user.',
      plans: ['5', '10', '20', '50'],
    },
    storage: {
      name: 'Document storage term',
      tooltip:
        "The cost of storage has never been cheaper and we're happy to retain documents for (nearly) the life of our partnership.",
      plans: ['5 years', '10 years', '15 years', '20 years'],
    },
    pro: {
      name: 'Pro services discount',
      tooltip: 'Partner rate at $225 per hour.',
      plans: ['0%', '10%', '15%', '20%'],
    },
    annual: {
      name: 'Annual free product classification',
      tooltip:
        '$5 per additional classification (minus pro services discount).',
      plans: ['0', '100', '250', '500'],
    },
    bond: {
      name: 'Continuous bond discount',
      tooltip: 'Discount taken from our normal rate of $249 per year.',
      plans: ['0%', '10%', '15%', '20%'],
    },
    track: {
      name: 'Real-time track and trace',
      tooltip:
        'Not your run of the mill track and trace - you will get import date, entry date, release date, date delivery order was issued, and last free day',
      plans: [true],
    },
    exam: {
      name: 'Exam resolution',
      tooltip:
        'You will never be expected to contact customs directly, we resolve all holds and exams on your behalf.',
      plans: [true],
    },
    pga: {
      name: 'Partner Government Agency filing',
      tooltip:
        'Make declarations to all other government agencies that regulate the import of your product(s).',
      plans: [true],
    },
    wizard: {
      name: 'Product upload wizard',
      tooltip:
        'You already have a product database? Our upload wizard allows you to import your product library for ease-of-use and compliance.',
      plans: [true],
    },
    edu: {
      name: 'Full educational webinar access',
      tooltip:
        'INLT was born out of a trade consulting firm and training is our sweet spot, you can expect monthly training webinars at no cost.',
      plans: [true],
    },
  },
};
