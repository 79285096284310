import React from 'react';
// import BondForm from 'components/BondForm'; removed as wanted by biz
import { Divider } from 'antd';
import RawHTML from 'components/RawHTML';
import './Bonds.less';
import PublicPage from 'abstract/PublicPage';
import FAQ from 'components/FAQ';

class Bonds extends PublicPage {
  fields = ['copy', 'faqs'];

  prefix = 'bonds';

  pageContent = () => {
    const { copy, faqs } = this.state.data;
    return (
      <div>
        <RawHTML className="bonds-copy" html={copy} />
        {/* <BondForm /> */}
        <h2>Customs Bonds FAQ</h2>
        <FAQ faqs={faqs} />
      </div>
    );
  };
}

export default Bonds;
