import React from 'react';
import { Button, Spin } from 'antd';
import './Loading.less';

const Loading = (props) => {
  const { retry, isLoading, error, timedOut, isComponent } = props;
  // Handle the loading state
  if (isLoading) {
    return isComponent ? null : (
      <div className="content loading">
        <div className="content-main loading">
          <Spin className="loading-spin" />
        </div>
      </div>
    );
  }
  // Handle the error state
  else if (error || timedOut) {
    // eslint-disable-next-line no-console
    console.log(error);
    return isComponent ? (
      <Button onClick={retry}>Retry</Button>
    ) : (
      <div className="loading content">
        <div className="content-header">
          <h1>Uh oh</h1>
        </div>
        <div className="content-main loading">
          <div>
            <p>Sorry, there was a problem loading the page.</p>
            {!['prod', 'qa'].includes(process.env.REACT_APP_STAGE) && (
              <p>{error.toString()}</p>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Loading;
