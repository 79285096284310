import React from 'react';
import { Route, Switch } from 'react-router-dom';
import appPaths from './appPaths';
import NotFound from 'components/NotFound';
import RedirectRoute from './RedirectRoute';

import Home from 'components/PublicHome'; // TODO CHANGE THE NAME OF THIS FILE WHEN MOVING IT
// import Compliance from 'components/Compliance'; removed as wanted by biz
import Classification from 'components/PublicClassification'; // TODO
// import ManifestConfidentiality from 'components/ManifestConfidentiality'; remove as wanted by biz
// import LogisticsConsulting from 'components/LogisticsConsulting'; removed as wanted by biz
import Brokerage from 'components/Brokerage';
import ProServices from 'components/ProServices';
// import Ctpat from 'components/Ctpat'; removed as wanted by biz
// import Isa from 'components/Isa'; removed as wanted by biz
import Bonds from 'components/Bonds';
// import ImporterPricing from 'components/ImporterPricing'; the /pricing page will now just be the forwarder pricing page 1/8/2021
import NvoPricing from 'components/NvoPricing';
import About from 'components/About';
import Contact from 'components/Contact';
import Careers from 'components/Careers';
import WhySwitch from 'components/Switch';
// import Blog from 'components/Blog'; removed as wanted by biz
// import Category from 'components/Category'; removed as wanted by biz
import Terms from 'components/Terms';
import Dmca from 'components/Dmca';
import Privacy from 'components/Privacy';
import Press from 'components/Press';
import Glossary from 'components/Glossary';
// import Trump from 'components/Trump';

export default ({ location }) => (
  <div className="page-main">
    <section className="page-main-inner">
      <Switch location={location}>
        <Route path="/" exact component={Home} />
        <Route path="/terms" exact component={Terms} />
        <Route path="/dmca" exact component={Dmca} />
        <Route path="/privacy" exact component={Privacy} />
        {/* <Route path="/section301" exact component={Trump} /> */}

        {/* <Route path="/compliance" exact component={Compliance} /> */}
        <Route path="/classification" exact component={Classification} />
        {/* <Route
          path="/manifest-confidentiality"
          exact
          component={ManifestConfidentiality}
        /> */}
        {/* <Route
          path="/logistics-consulting"
          exact
          component={LogisticsConsulting}
        /> */}
        <Route path="/brokerage" exact component={Brokerage} />
        <Route path="/pro-services" exact component={ProServices} />
        {/* <Route path="/ctpat" exact component={Ctpat} /> */}
        {/* <Route path="/isa" exact component={Isa} /> */}
        <Route path="/bonds" exact component={Bonds} />
        <Route path="/pricing" exact component={NvoPricing} />
        {/* <Route path="/nvopricing" exact component={NvoPricing} /> */}
        <Route path="/about" exact component={About} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/careers" exact component={Careers} />
        <Route path="/switch" exact component={WhySwitch} />
        {/* <Route path="/blog" exact component={Blog} />
        <Route path="/blog/page/:page" component={Blog} />
        <Route path="/blog/posts/:slug" component={Blog} />
        <Route path="/blog/categories/:slug" component={Category} /> */}
        <Route path="/press" exact component={Press} />
        <Route path="/glossary" exact component={Glossary} />
        {appPaths.map((path) => (
          <RedirectRoute key={path} exact path={path} location={location} />
        ))}

        <Route component={NotFound} />
      </Switch>
    </section>
  </div>
);
