import React from 'react';
import dompurify from 'dompurify';
import PropTypes from 'prop-types';
import './style.less';

const RawHTML = ({ html, className }) => {
  const sanitizer = dompurify.sanitize;
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: sanitizer(html) }}
    />
  );
};

RawHTML.propTypes = {
  html: PropTypes.string,
  className: PropTypes.string,
};

export default RawHTML;
