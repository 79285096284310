import AWS from 'aws-sdk/global';
import sigV4Client, { buildCanonicalQueryString } from 'libs/sigV4Client';
import { Cognito } from 'libs/cognito';
import axios from 'axios';
import { prefix } from 'libs/env';

AWS.config.correctClockSkew = true;

// const offlineHost = 'https://192.168.1.91';
// const offlineHost = 'https://INLT_DEV';
//

const getEndpoint = (path) => {
  const endpoint = process.env[prefix + 'API_GATEWAY_ENDPOINT'];
  const publicApis = ['/bonds', '/market', '/sign', '/forms'];
  let mustAuthenticate = true;
  publicApis.forEach((api) => {
    if (path.startsWith(api)) mustAuthenticate = false;
  });
  if (path === '/graphql') mustAuthenticate = false;
  return { mustAuthenticate, endpoint };
};

/**
 * Inlt client side API request method (AWS API Gateway)
 */
export class Apig {}
Apig.invoke = async ({
  path,
  method = 'GET',
  headers = {},
  queryParams = {},
  body,
}) => {
  let { endpoint, mustAuthenticate } = getEndpoint(path);
  // hack
  const graphQLError = (ret) => {
    if (path.includes('graphql') && ret.data.errors) {
      throw new Error(ret.data.errors[0].message);
    }
  };
  if (
    (path === '/users/signup' && method === 'POST') ||
    path === '/forwarders/owner/info' ||
    path === '/comm/linkAction'
  )
    mustAuthenticate = false;

  if (mustAuthenticate) {
    const credentials = await Cognito.authUser();
    if (!credentials) {
      throw new Error('User is not logged in');
    }
    headers['x-api-key'] = process.env.REACT_APP_INLT_API_KEY;
    if (process.env.REACT_APP_STAGE === 'dev')
      headers['cognito-identity-id'] = Cognito.getUserId();
    const signedRequest = sigV4Client
      .newClient({
        accessKey: credentials.accessKeyId,
        secretKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
        region: process.env[prefix + 'API_GATEWAY_REGION'],
        endpoint: endpoint,
      })
      .signRequest({
        method,
        path,
        headers,
        queryParams,
        body,
      });

    headers = signedRequest.headers;
    body = body && typeof body === 'object' ? JSON.stringify(body) : body;

    const ret = await axios({
      url: signedRequest.url,
      method,
      headers,
      data: body,
    });
    graphQLError(ret);
    if (process.env.REACT_APP_REACT.toString() === '1') return ret.data;
    return ret;
  } else {
    body = body && typeof body === 'object' ? JSON.stringify(body) : body;

    headers['Accept'] = 'application/json';
    headers['Content-Type'] = 'application/json';
    headers['x-api-key'] = process.env.REACT_APP_INLT_API_KEY;

    let queryString = buildCanonicalQueryString(queryParams);
    if (queryString !== '') {
      queryString = '?' + queryString;
    }

    const ret = await axios({
      url: endpoint + path + queryString,
      method,
      headers,
      data: body,
    });
    graphQLError(ret);
    if (process.env.REACT_APP_REACT.toString() === '1') return ret.data;
    return ret;
  }
};
Apig.graphql = ({ query, variables, path = '/user/graphql', headers = {} }) =>
  Apig.invoke({
    path,
    method: 'POST',
    body: {
      query,
      variables,
    },
    headers,
  });
