import { handleActions } from 'redux-actions';
import moment from 'moment';

const defaultSignup = {
  newsletterVisible: false,
  signupVisible: false,
  captured: false,
};

export default handleActions(
  {
    NEWSLETTER_SHOW: (state, { payload: visible }) => {
      const now = moment();
      const oneDayAgo = moment().subtract(1, 'days');
      if (!visible) {
        return { ...state, newsletterVisible: false };
      } else if (
        !state.captured &&
        !state.signupVisible &&
        visible &&
        (!state.previouslyShown ||
          moment(state.previouslyShown).isBefore(oneDayAgo))
      ) {
        return { ...state, previouslyShown: now, newsletterVisible: true };
      } else {
        return state;
      }
    },
    SIGNUP_SHOW: (state, { payload: visible }) => {
      return { ...state, signupVisible: visible };
    },
    SIGNUP_CAPTURE: (state, { payload }) => {
      return { ...state, captured: true };
    },
  },
  defaultSignup,
);
